import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FirstGrid = styled.div`
  width: 260px;
  flex-shrink: 0;
  margin-right: 70px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1140px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1140px) {
    flex-grow: unset;
    width: 100%;
  }
`;

export const BackButtonContainer = styled.div`
  margin-right: 40px;
`;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 85px;
  margin: 0 0 41px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: var(--caren-icongrey);
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }

  button {
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--caren-icongrey);
    background-color: var(--caren-green);
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: var(--caren-darkgreen);
    }
  }
`;

export const SectionTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: var(${props => props.color});
  border-bottom: 1px solid rgba(215, 215, 215, 0.5);
`;

export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;

  > span {
    color: #47525e;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 1140px) {
      font-size: 14px;
    }
  }

  p.empty {
    color: var(--caren-grey);
    background: none;
    margin: 200px 0;
  }
`;

export const WrapperLoading = styled.div<{ size?: number }>`
  display: inline-block;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #ffc476;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const TeamTable = styled.table`
  table-layout: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  color: #455054;
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 640px) {
    display: inline-table;
  }

  > thead {
    display: none;

    @media (min-width: 640px) {
      display: table-header-group;
    }

    th {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }
    }
  }

  > tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (min-width: 640px) {
      row-gap: none;
      display: table-row-group;
    }

    tr {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      border-bottom: 1px solid var(--caren-input-border);
      padding-bottom: 10px;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      @media (min-width: 640px) {
        display: table-row;
        row-gap: none;
        border: none;
        padding-bottom: 0;

        height: 50px;
      }
    }

    td {
      padding: 4px 6px;

      &.center {
        text-align: center;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;

        > button {
          width: 30px;
          height: 30px;
          padding: 2px;
          border-radius: 10px;
        }
      }

      > span {
        display: block;
        font-weight: bold;
        width: 100%;
        text-align: center;

        @media (min-width: 640px) {
          display: none;
        }
      }

      p {
        position: relative;

        span {
          background-color: var(--caren-clockgrey);
          color: white;
          padding: 8px 10px;
          border-radius: 8px;
          white-space: nowrap;
          position: absolute;
          top: calc(50% - 2px);
          right: calc(100% + 10px);
          pointer-events: none;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
          z-index: 10;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &:hover span {
          opacity: 1;
        }
      }

      p.warning {
        color: var(--caren-orange);
      }

      p.check,
      button.check svg path {
        color: var(--caren-action-button);
        fill: var(--caren-action-button);
      }

      p.progress {
        color: var(--caren-bluebtn);
      }

      div.anamnese {
        color: var(--caren-orange);
        fill: var(--caren-orange);
      }

      > button,
      a {
        background-color: transparent;

        svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-orange);
            }
          }

          path {
            fill: #969faa;
            transition-property: fill;
            transition-duration: 0.5s;
            transition-timing-function: ease;
          }
        }

        &:hover svg {
          &[data-warning='true'] {
            path {
              fill: var(--caren-warning);
            }
          }

          path {
            fill: #53d3b0;
          }
        }
      }

      button.warning-button {
        color: var(--caren-orange);
        border: 2px solid var(--caren-orange);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
        border-radius: 10px;
        padding: 0 4px;
        text-align: center;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-warning);
          border-color: var(--caren-warning);
          color: var(--caren-white);
        }
      }

      a.green-button {
        color: var(--caren-action-button);
        border: 2px solid var(--caren-action-button);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: #53d3b0;
          border-color: #53d3b0;
          color: var(--caren-white);
        }
      }

      a.blue-button {
        color: var(--caren-bluebtn);
        border: 2px solid var(--caren-bluebtn);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4px;
        border-radius: 10px;
        width: 100%;
        height: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: var(--caren-lightblue);
          border-color: var(--caren-lightblue);
          color: var(--caren-white);
        }
      }

      div.perfil {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        @media (min-width: 640px) {
          flex-direction: row;
        }

        & > div.empty {
          width: 48px;
          height: 48px;
          border-radius: 100%;
          background-color: var(--caren-image-fallback);
        }
      }
    }
  }
`;
