import styled from 'styled-components/macro';

export const FormContainer = styled.form`
  input {
    background-color: var(--caren-white);
    border: 1px solid var(--caren-icongrey);
    border-radius: 5px;
    padding-left: 5px;
    height: 35px;
    margin: 10px 0;
    width: 50%;
    font-size: 16px;
    margin-right: auto;
  }

  input:disabled {
    background-color: unset;
    color: var(--caren-grey);
  }

  label {
    color: var(--caren-icongrey);
    margin: 10px 0;
    width: 30%;
  }

  div {
    display: flex;
    align-items: start;
  }

  .MuiInputBase-input:focus {
    box-shadow: none;
  }
`;

export const UpdateProfileButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin: 10px 0;
  width: 100%;
  font-size: 16px;
  margin-right: auto;
  color: var(--caren-white);
  background-color: var(--caren-green);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessageText = styled.p`
  color: red;
  font-size: 0.8em;
`;

export const InputBox = styled.div`
  justify-content: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 50%;

    > input {
      width: 100%;
    }
  }
`;
