import styled from 'styled-components';
import { useContext } from 'react';
import { ConsultDialogContext } from '../context';
import Calendar from '../elements/calendar';
import { type Doctor } from '../../../entities/doctor';

const Step = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  > footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (max-width: 636px) {
      width: 100%;
    }

    p {
      color: black;
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 636px) {
        width: 100%;
        column-gap: 10px;
      }

      button {
        color: var(--caren-icongrey);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        @media (max-width: 636px) {
          min-width: unset;
          width: 100%;
        }

        &:disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.next {
          margin-top: 10px;
          background-color: #fdbf71;
          text-transform: uppercase;
          &:hover {
            background-color: #f3ac4e;
          }
        }

        &.back {
          min-width: 150px;
          background-color: var(--caren-lightgrey);
          color: var(--caren-white);

          @media (max-width: 636px) {
            min-width: unset;
          }

          &:hover {
            filter: brightness(115%);
          }
        }
      }
    }
  }
`;

type ScheduleStepProps = {
  step: number;
  doctor?: Doctor;
  event?: string;
};

export default function ScheduleStep({
  step,
  doctor,
  event
}: ScheduleStepProps) {
  const dialogContext = useContext(ConsultDialogContext);

  const selectedDoctor =
    doctor ??
    (dialogContext?.isManager && dialogContext.steps.get(1)
      ? (dialogContext.steps.get(1)?.value as Doctor)
      : null);

  if (dialogContext === null || dialogContext.actualStep !== step) return null;

  return (
    <Step>
      <Calendar
        selectedDoctor={selectedDoctor}
        editedEvent={event}
        onSelectDate={event => {
          if (event === null) {
            dialogContext.removeStepData(step);
          } else if (event.text.startTime === event.text.endTime) {
            dialogContext.setStepData(step, {
              header: `${event.text.day} - ${event.text.startTime}`,
              value: event
            });
          } else {
            dialogContext.setStepData(step, {
              header: `${event.text.day} - ${event.text.startTime} até ${event.text.endTime}`,
              value: event
            });
          }
        }}
      />

      <footer>
        <div>
          {step > 1 && (
            <button
              className="back"
              type="button"
              onClick={() => {
                dialogContext.backwardStep();
              }}
            >
              VOLTAR
            </button>
          )}
          <button
            type="button"
            className="next"
            disabled={!dialogContext.steps.get(step)}
            onClick={() => {
              dialogContext.forwardStep();
            }}
          >
            Avançar
          </button>
        </div>
        <p>
          Clique em um espaço vazio para adicionar um novo agendamento.
          <br />
          Se necessário, é possível arrastar o agendamento criado.
        </p>
      </footer>
    </Step>
  );
}
