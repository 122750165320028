import styled from 'styled-components/macro';
import InputMask from 'react-input-mask';

export const ModalWrapper = styled.div`
  width: 100%;
`;

export const WrapperLoading = styled.div`
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #ffc476;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const PatientInfoContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 150px;
  padding: 32px;
  margin-top: 15px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 20px;
    height: auto;
    border-radius: 10px;
  }
`;

export const ConsultationContainer = styled.div`
  background-color: white;
  position: relative;
  border-radius: 20px;
  padding: 40px 35px;
  margin-top: 20px;

  @media (max-width: 1140px) {
    padding: 20px;
    border-radius: 10px;
  }
`;

export const ModalButton = styled.button`
  border-radius: 5px;
  height: 45px;
  margin: 20px 0 10px;
  width: 50%;
  font-size: 16px;
  color: var(--caren-icongrey);
  text-transform: uppercase;
  background-color: ${({ bgColor }) => bgColor || 'var(--caren-green)'};

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const MaskInput = styled(InputMask)`
  height: 45px;
  font-size: 1rem;
  background-color: transparent;
  padding: 26px 0 26px 13px;
  border: 1px solid #bdbdbd;
  margin-bottom: 10px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
`;

export const ErrorMessageText = styled.p`
  color: red;
  font-size: 1rem;
  text-align: center;
`;
