import { Helmet } from 'react-helmet-async';
import {
  AssignmentTurnedIn,
  AssignmentLate,
  AccessTime
} from '@mui/icons-material';
import { useCallback, useState } from 'react';
import Header from '../../components/Header';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import ContainerOpacity from '../../components/ContainerOpacity';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { ReactComponent as SmsIcon } from '../../assets/icons/sms-tracking.svg';
import {
  ConsultsTable,
  SecretaryConsults,
  SecretaryHeader,
  SecretaryHome
} from './styles';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import ImageOrName from '../../components/ImageOrName';
import { useNextConsults } from '../../v2/hooks/secretary/useConsults';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import { FORMAT } from '../../utils/moment/momentFormat';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import { useToast } from '../../contexts/ToastContext';
import { resendConsultInvitation } from '../../services/secretary';
import Modal from '../../components/Modal';
import { type Consult, type Teleconsultation } from '../../v2/entities/consult';
import SecretaryPanel from '../../components/SecretaryPanel';
import useCurrentDatetime from '../../hooks/currentDatetime.hook';
import SuccessStep from '../../v2/dialogs/consult/steps/success';
import { Table } from '../../v2/components/table';
import IconButton from '../../v2/components/inputs/buttons/IconButton';
import {
  doctorCancelConsultationService,
  doctorDeleteInvitationService,
  doctorResendInvitationService
} from '../../services/doctors.service';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { type Doctor } from '../../v2/entities/doctor';
import { mutate } from 'swr';

export default function SecretaryClinic() {
  const currentHourState = useCurrentDatetime();
  const { consults, isError, isLoading } = useNextConsults();
  const toast = useToast();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [invitationLink, setInvitationLink] = useState<string | null>(null);
  const [unreversibleType, setUnreversibleType] = useState<
    null | 'delete' | 'resend' | 'cancel'
  >(null);
  const [openUnreversible, setOpenUnreversible] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);

  const isConfirmed = (item: Consult) =>
    item.invitation.accepted_at !== null && item.teleconsultation !== null;

  const handleToggleConfirmationModal = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  async function handleCancelSchedule(key: string, doctor: Doctor) {
    if (!key) return;

    try {
      await doctorCancelConsultationService(key, doctor);

      setSelectedDoctor(null);

      mutate('/employees/schedule?today=true');
      mutate('/employees/schedule?tomorrow=true');
      mutate('/employees/schedule?next=true');

      toast('Consulta cancelada com sucesso.', {
        variant: 'success'
      });
    } catch (error) {
      toast('Ops, houve um erro no cancelamento...', {
        variant: 'error'
      });
    }
  }

  const resendInvite = useCallback(async (key: string, doctor: Doctor) => {
    if (key.length <= 0) return;

    try {
      const { link } = await resendConsultInvitation(key, doctor);

      setSelectedDoctor(null);

      setInvitationLink(link);

      toast('Convite reenviado com sucesso!', {
        variant: 'success'
      });

      setOpenConfirmation(true);
    } catch (e) {
      toast('Ops, não foi possível reenviar o convite :(', {
        variant: 'error'
      });
    }
  }, []);

  async function handleDeleteSchedule(key: string, doctor: Doctor) {
    if (!key) return;

    try {
      await doctorDeleteInvitationService(key, doctor);

      setSelectedDoctor(null);

      mutate('/employees/schedule?today=true');
      mutate('/employees/schedule?tomorrow=true');
      mutate('/employees/schedule?next=true');

      toast('Agendamento deletado com sucesso.', {
        variant: 'success'
      });
    } catch (error) {
      toast('Ops, houve um erro na exclusão...', {
        variant: 'error'
      });
    }
  }

  const decideUnreversibleTitle = useCallback(() => {
    if (unreversibleType === 'resend') {
      return 'Você tem certeza de que deseja reenviar esse convite?';
    }
    if (unreversibleType === 'delete') {
      return 'Você tem certeza de que deseja cancelar esta teleconsulta?\n\nIsto não pode ser desfeito.';
    }
    if (unreversibleType === 'cancel') {
      return 'Você tem certeza de que deseja cancelar esse convite?';
    }

    return null;
  }, [unreversibleType]);

  const handleConfirmation = useCallback(
    (status: boolean) => {
      if (status && selectedKey) {
        if (unreversibleType === 'resend' && selectedDoctor) {
          resendInvite(selectedKey, selectedDoctor);
        } else if (unreversibleType === 'cancel' && selectedDoctor) {
          handleCancelSchedule(selectedKey, selectedDoctor);
        } else if (unreversibleType === 'delete' && selectedDoctor) {
          handleDeleteSchedule(selectedKey, selectedDoctor);
        }
      }

      setUnreversibleType(null);
      setSelectedKey(null);
      setOpenUnreversible(false);
    },
    [selectedDoctor, selectedKey, unreversibleType]
  );

  const handleCopyLinkToAnamnesis = async (schedule: Consult | null) => {
    if (!schedule) {
      toast('Ops, houve um erro ao gerar novo o novo link...', {
        variant: 'error'
      });

      return;
    }

    const { link } = await doctorResendInvitationService(
      schedule.invitation.key,
      null,
      false
    );

    navigator.clipboard.writeText(link);
    toast(
      'Link para preenchimento da anamnese copiado! Favor enviar ao paciente.',
      {
        variant: 'success'
      }
    );
  };

  const handleCopyLinkToViewAnamnesis = (
    teleconsultation: Teleconsultation | null
  ) => {
    if (!teleconsultation) return;

    const link = `${window.location.origin}/consultas/${teleconsultation.key}/anamnese/${teleconsultation.anamnesis_key}`;

    navigator.clipboard.writeText(link);
    toast('Link para visualização da anamnese copiado!', {
      variant: 'success'
    });
  };

  const handleCopyLinkToConsultRoom = (
    teleconsultation: Teleconsultation | null
  ) => {
    if (!teleconsultation) return;
    const link = `${window.location.origin}/consultas/${teleconsultation.key}/sala-de-espera`;

    navigator.clipboard.writeText(link);
    toast('Link para sala de espera copiado!', {
      variant: 'success'
    });
  };

  const maybeDisableButton = (consult: Consult) => {
    const hasSecretaryFeature =
      consult?.employee?.active_subscription?.plan.enable_secretaries;

    if (
      consult.invitation.insurance_partner ??
      consult?.teleconsultation?.insurance_coverage ??
      consult?.invitation.free_trial ??
      hasSecretaryFeature
    ) {
      return false;
    }

    return true;
  };

  const canShowActions = (consult: Consult) => {
    const hasSecretaryFeature =
      consult?.employee?.active_subscription?.plan?.enable_secretaries;

    const canShow =
      consult.invitation?.insurance_partner ??
      consult?.teleconsultation?.insurance_coverage ??
      consult?.invitation.free_trial ??
      hasSecretaryFeature;

    return canShow;
  };

  const renderAnamnesisButton = (consult: Consult) => {
    const hasSecretaryFeature =
      consult?.employee?.active_subscription?.plan.enable_secretaries;

    const canShow =
      consult.invitation?.insurance_partner ??
      consult?.teleconsultation?.insurance_coverage ??
      consult?.invitation.free_trial ??
      hasSecretaryFeature;

    if (!canShow) return null;

    if (consult?.teleconsultation) {
      return (
        <>
          {consult.teleconsultation.flow_concluded ? (
            <button
              type="button"
              title="Copiar link da anamnese para o médico"
              aria-label="Copiar link da anamnese para o médico"
              className="check"
              onClick={() => {
                handleCopyLinkToViewAnamnesis(consult.teleconsultation);
              }}
              disabled={maybeDisableButton(consult)}
            >
              <AssignmentTurnedIn />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                handleCopyLinkToAnamnesis(consult);
              }}
              title="Copiar link da anamnese para o paciente"
              aria-label="Copiar link da anamnese para o paciente"
              disabled={maybeDisableButton(consult)}
            >
              <AssignmentLate />
            </button>
          )}
        </>
      );
    }

    return (
      <button
        onClick={() => {
          setSelectedKey(consult.invitation.key);
          setUnreversibleType('resend');
          setOpenUnreversible(true);
        }}
        className="anamnese"
        type="button"
        title="Reenviar confirmação de consulta"
        aria-label="Reenviar confirmação de consulta"
        disabled={maybeDisableButton(consult)}
      >
        <AccessTime />
      </button>
    );
  };

  return (
    <>
      <Helmet title="Clínica - Caren"></Helmet>
      <ContainerOpacity />
      <Header />
      <UserSidebar />
      <SecretaryHome>
        <SecretaryPanel
          onResendClick={key => {
            setSelectedKey(key);
            setUnreversibleType('resend');
            setOpenUnreversible(true);
          }}
        />
        <div>
          <SecretaryHeader>
            <div className="info">
              <h2>Bem-vindo(a) ao seu consultório virtual!</h2>
              <p>{currentHourState}</p>
            </div>
          </SecretaryHeader>
          {!isError && !isLoading && (
            <SecretaryConsults>
              <div>
                <p>Consultas de Hoje</p>
                {consults.today && consults.today.length !== 0 ? (
                  <ConsultsTable>
                    <thead>
                      <tr>
                        <th scope="col">Médico</th>
                        <th scope="col">Paciente</th>
                        <th scope="col">Plano</th>
                        <th scope="col" className="center">
                          Horário
                        </th>
                        <th scope="col" className="center">
                          Status
                        </th>
                        <th scope="col" className="center">
                          Anamnese
                        </th>
                        <th scope="col" className="center">
                          Ações
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {consults.today.map(consultation => (
                        <tr key={consultation.key}>
                          <td>
                            <div className="perfil">
                              <ImageOrName
                                src={consultation?.employee?.avatar_url}
                                title={consultation.employee?.name}
                                titleSize={16}
                                backgroundStyle={{
                                  width: 48,
                                  height: 48,
                                  borderRadius: '100%',
                                  backgroundColor: 'var(--caren-image-fallback)'
                                }}
                              />{' '}
                              <p>{consultation.employee?.name}</p>
                            </div>
                          </td>
                          <td>
                            <div className="perfil">
                              <ImageOrName
                                src={consultation?.patient?.avatar_url}
                                title={
                                  consultation.patient_name ??
                                  consultation.invitation.email
                                }
                                titleSize={16}
                                backgroundStyle={{
                                  width: 48,
                                  height: 48,
                                  borderRadius: '100%',
                                  backgroundColor: 'var(--caren-image-fallback)'
                                }}
                                iconSyle={{
                                  width: 40,
                                  height: 40
                                }}
                              />
                              <p>
                                {consultation.patient_name &&
                                consultation.patient_name !== 'Paciente'
                                  ? consultation.patient_name
                                  : consultation.invitation.email}
                              </p>
                            </div>
                          </td>
                          <td className="center">
                            <span>Plano</span>
                            <p>
                              {consultation.invitation.insurance_partner ??
                                'Particular'}
                            </p>
                          </td>
                          <td className="center">
                            <span>Horário</span>
                            <p>
                              {momentUtcLocal(
                                consultation.start_datetime
                              ).format(FORMAT['hour-and-minute'])}
                            </p>
                          </td>
                          <td className="center">
                            <span>Status</span>
                            {consultation.teleconsultation &&
                              consultation.teleconsultation.flow_concluded && (
                                <p
                                  className={
                                    consultation.teleconsultation.open &&
                                    consultation.teleconsultation.room_opened_at
                                      ? 'progress'
                                      : 'check'
                                  }
                                >
                                  {consultation.teleconsultation.open &&
                                  consultation.teleconsultation.room_opened_at
                                    ? 'Em progresso'
                                    : 'Confirmado'}
                                </p>
                              )}
                            {consultation.teleconsultation &&
                              !consultation.teleconsultation.flow_concluded && (
                                <p className="check">
                                  Confirmado
                                  <span>
                                    O paciente precisa preencher a anamnese
                                  </span>
                                </p>
                              )}
                            {!consultation.teleconsultation && (
                              <p className="warning">
                                Pendente
                                <span>
                                  O paciente precisa confirmar a consulta
                                </span>
                              </p>
                            )}
                          </td>
                          <td className="center">
                            <span>Anamnese</span>
                            {renderAnamnesisButton(consultation)}
                          </td>
                          <td>
                            <span>Ações</span>
                            <div className="flex w-full items-center justify-center">
                              <Table.Cell.Actions
                                actions={
                                  canShowActions(consultation)
                                    ? [
                                        <IconButton
                                          key="action-button-1"
                                          title="Copiar link da Sala de Espera"
                                          disabled={
                                            consultation.teleconsultation ===
                                            null
                                          }
                                          onClick={() => {
                                            handleCopyLinkToConsultRoom(
                                              consultation.teleconsultation
                                            );
                                          }}
                                          icon={CameraIcon}
                                          type="primary"
                                        />,
                                        <IconButton
                                          key="action-button-2"
                                          title="Reenviar convite"
                                          onClick={() => {
                                            setSelectedKey(
                                              consultation.invitation.key
                                            );
                                            setSelectedDoctor(
                                              consultation.employee
                                            );
                                            setUnreversibleType('resend');
                                            setOpenUnreversible(true);
                                          }}
                                          icon={SmsIcon}
                                          disabled={maybeDisableButton(
                                            consultation
                                          )}
                                        />,
                                        <IconButton
                                          key="action-button-3"
                                          title={
                                            isConfirmed(consultation)
                                              ? 'Cancelar Consulta'
                                              : 'Deletar Convite'
                                          }
                                          onClick={() => {
                                            setSelectedDoctor(
                                              consultation.employee
                                            );
                                            setUnreversibleType(
                                              isConfirmed(consultation)
                                                ? 'cancel'
                                                : 'delete'
                                            );
                                            setSelectedKey(
                                              isConfirmed(consultation) &&
                                                consultation.teleconsultation
                                                ? consultation.teleconsultation
                                                    .key
                                                : consultation.invitation.key
                                            );
                                            setOpenUnreversible(true);
                                          }}
                                          icon={TrashIcon}
                                        />
                                      ]
                                    : []
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ConsultsTable>
                ) : (
                  <span>Nenhum paciente encontrado para hoje.</span>
                )}
              </div>
              <div>
                <p>Próximas Consultas</p>
                {consults.tomorrow && consults.tomorrow.length !== 0 ? (
                  <ConsultsTable>
                    <thead>
                      <tr>
                        <th scope="col">Paciente</th>
                        <th scope="col" className="center">
                          Data
                        </th>
                        <th scope="col" className="center">
                          Status
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {consults.tomorrow.map(consultation => (
                        <tr key={consultation.key}>
                          <td>
                            <p>
                              {consultation.patient_name &&
                              consultation.patient_name !== 'Paciente'
                                ? consultation.patient_name
                                : consultation.invitation.email}
                            </p>
                          </td>

                          <td className="center">
                            <span>Data</span>
                            <p>
                              {momentUtcLocal(
                                consultation.start_datetime
                              ).format(FORMAT['pt-date'])}
                            </p>
                          </td>

                          <td className="center">
                            <span>Status</span>
                            {consultation.teleconsultation &&
                              consultation.teleconsultation.flow_concluded && (
                                <p
                                  className={
                                    consultation.teleconsultation.open &&
                                    consultation.teleconsultation.room_opened_at
                                      ? 'progress'
                                      : 'check'
                                  }
                                >
                                  {consultation.teleconsultation.open &&
                                  consultation.teleconsultation.room_opened_at
                                    ? 'Em progresso'
                                    : 'Confirmado'}
                                </p>
                              )}
                            {consultation.teleconsultation &&
                              !consultation.teleconsultation.flow_concluded && (
                                <p>
                                  Confirmado
                                  <span>
                                    O paciente precisa preencher a anamnese
                                  </span>
                                </p>
                              )}
                            {!consultation.teleconsultation && (
                              <p className="warning">
                                Pendente
                                <span>
                                  O paciente precisa confirmar a consulta
                                </span>
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ConsultsTable>
                ) : (
                  <span>Nenhum paciente encontrado para os próximos dias.</span>
                )}
              </div>
            </SecretaryConsults>
          )}
        </div>
      </SecretaryHome>

      <UnreversibleConfirmationModal
        title={decideUnreversibleTitle()}
        openModal={openUnreversible}
        setOpenModal={setOpenUnreversible}
        handleConfirmation={handleConfirmation}
        cancelButtonText={null}
        confirmButtonText={null}
      />

      <Modal
        handleOpenModal={() => {
          setOpenConfirmation(!openConfirmation);
        }}
        openModal={openConfirmation}
        modalTitle="Convite Enviado!"
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside
      >
        <SuccessStep
          invitationLink={invitationLink ?? ''}
          onClose={handleToggleConfirmationModal}
        />
      </Modal>

      <Tawk />
      <Chatwoot />
    </>
  );
}
