import { createGlobalStyle } from 'styled-components/macro';

export const CAREN_GREEN = '#FFC476';
export const CAREN_GREENBLUE = '#F3AC4E';
export const CAREN_BLUE = '#FFE1A6';

export default createGlobalStyle`
  :root {
    --caren-green: ${CAREN_GREEN};
    --caren-green-blur: #FDBF714a;
    --caren-lightgreen: #FFE1A6;
    --caren-darkgreen: #F3AC4E;
    --caren-greenblue: #FFC476;
    --caren-lightblue: #FFE1A6;
    --caren-bluebtn: #FFC476;
    --caren-white: #fff;
    --caren-grey: #47525E;
    --caren-lightgrey: #455054;
    --caren-icongrey: #262C2E;
    --caren-blue: ${CAREN_BLUE};
    --caren-red: #F95F62;
    --caren-darkred: #FF450C;
    --caren-orange: #FBA474;
    --caren-warning: #FBA474;
    --caren-subheader: #ECECEC;
    --caren-image-fallback: #ECECEC;
    --caren-input: #ECECEC;
    --caren-input-border: #D9D9D9;
    --caren-action-button: #FFC476;
    --caren-calendar-available-day: #e0f3e4;
    --caren-calendar-selected-day: #F3AC4E;
    --caren-footer-icons: #9a9fa5;
    --caren-disabled-text: #bbb;
    --caren-success: #009D79;
    --caren-yellow: #d2c54f;
    --caren-clockgrey: #8b929c;
    --caren-clocklightgrey: #455054;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    font-family: 'Nunito Sans', sans-serif;
    background-color: #f8f8f8 ;
    height: unset;
  }

  .vertical-timeline-custom-line::before {
    background-color: #f2f2f2;
  }

  .vertical-timeline-element-content {
    background-color: #f2f2f2;
    color: var(--caren-grey);
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid #f2f2f2;
  }

  .MuiInputBase-input:focus {
    border: none;
    box-shadow: none;
  }

  // Workaround bug of duplicated legend
  .MuiOutlinedInput-notchedOutline legend {
    color: transparent;
  }

  .MuiPickersCalendarHeader-iconButton {
    z-index: 0 !important;
  }

  .MuiButton-label {
    font-family: 'Nunito Sans', sans-serif !important;
    letter-spacing: 0;
    color: var(--caren-grey);
  }

  .MuiSlider-root {
    color: var(--caren-green) !important;
  }

  .Toastify__toast--success {
    background: var(--caren-green) !important;
    border-radius: 5px;
    text-align: center;
  }

  .Toastify__toast--error {
    background: var(--caren-red) !important;
    border-radius: 5px;
    text-align: center;
  }

  .Toastify__toast--warning {
    background: var(--caren-yellow) !important;
    border-radius: 5px;
    text-align: center;
  }

  .fileContainer input[type=file] {
    width: 0;
  }

  jdiv jdiv.__jivoMobileButton {
    z-index: 499999;
  }

  #rc-imagesselect {transform:scale(0,77);-webkit-transform:scale(0,77);transform-origin:0 0;-webkit-transform-origin:0 0;}

  @media screen e (max-height: 575px){
    #rc-imagesselect, .g-recaptcha {transform:scale(0,77);-webkit-transform:scale(0,77);transform-origin:0 0;-webkit-transform-origin:0 0;}
  }

  @media only screen and (max-width: 500px) {
    .g-recaptcha {
    transform:scale(0.77);
    transform-origin:0 0;
    }
  }
`;
