import { useState, forwardRef, useImperativeHandle } from 'react';
import BaseDialog from '../base';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { resendEmailConfirmation } from '../../services/user';
import { useToast } from '../../../contexts/ToastContext';
import LoadingComponent from '../../../components/Loading';

type ResendConfirmationEmailDialogHandles = {
  open: () => void;
};

const ResendConfirmationEmailDialog =
  forwardRef<ResendConfirmationEmailDialogHandles>((_, ref) => {
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      }
    }));

    const handleResendEmailConfirmation = async () => {
      setLoading(true);

      const result = await resendEmailConfirmation();

      if (result) {
        toast(
          'Email de confirmação enviado com sucesso. Verifique sua caixa de entrada!',
          {
            variant: 'success'
          }
        );
      } else {
        toast('Não foi possível enviar o email de confirmação.', {
          variant: 'error'
        });
      }
      setLoading(false);
      setIsOpen(false);
    };

    return (
      <BaseDialog
        title="E-mail não confirmado"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        disableBackdropClick
      >
        <div className="h-full w-full">
          <div className="header flex flex-col items-center text-center text-sm font-semibold text-blue-650">
            <EnvelopeIcon className="h-16 w-16 shrink-0" />
            <p className="w-2/3 text-lg">
              Enviamos uma mensagem para o seu endereço de e-mail!
            </p>
            <p className="w-2/3 text-lg">
              Por favor clique no link para verificar a sua conta.
            </p>
          </div>
          <p className="mt-4 text-center text-yellow-750">
            Caso deseje reenviar o e-mail de confirmação, clique no botão
            abaixo:
          </p>
          <div className="mt-2 flex flex-col items-center">
            <button
              type="button"
              className="text-gray-850 h-14 w-fit rounded-md bg-yellow-650 px-10 font-nunito text-base font-bold uppercase transition-all duration-200 hover:bg-yellow-750"
              onClick={handleResendEmailConfirmation}
            >
              {loading ? <LoadingComponent /> : 'Reenviar'}
            </button>
          </div>
        </div>
      </BaseDialog>
    );
  });

ResendConfirmationEmailDialog.displayName = 'ResendConfirmationEmailDialog';
export type { ResendConfirmationEmailDialogHandles };
export default ResendConfirmationEmailDialog;
