import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 50px 40px 50px 65px;
  min-height: calc(100vh - 95px);

  @media (max-width: 1140px) {
    padding: 50px 25px;
  }
  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FirstGrid = styled.div`
  width: 260px;
  flex-shrink: 0;
  margin-right: 70px;

  @media (max-width: 1140px) {
    margin-right: 25px;
  }
  @media (max-width: 1140px) {
    width: 100%;
    max-width: 320px;
    margin: 0 0 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SecondGrid = styled.div`
  flex-grow: 1;

  @media (max-width: 1140px) {
    flex-grow: unset;
    width: 100%;
  }
`;

export const BackButtonContainer = styled.div`
  margin-right: 40px;
`;

export const SecondHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 85px;
  margin: 0 0 41px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 0 30px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: var(--caren-icongrey);
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }

  button {
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 16px;
    color: var(--caren-white);
    background-color: var(--caren-green);
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: var(--caren-darkgreen);
    }
  }
`;

export const SectionTitle = styled.p`
  width: 100%;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 15px;
  padding-bottom: 10px;
  color: var(${(props) => props.color});
  border-bottom: 1px solid rgba(215, 215, 215, 0.5);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 15px;

  > span {
    color: #47525e;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 1140px) {
      font-size: 14px;
    }
  }

  button.empty {
    font-weight: bold;
    font-size: 16px;
    color: var(--caren-grey);
    text-decoration: underline;
    background: none;
    margin: 200px 0;
    transition: all 300ms ease-in-out;

    &:hover {
      color: var(--caren-green);
    }
  }
`;

export const WrapperLoading = styled.div<{ size?: number }>`
  display: inline-block;
  width: ${({ size = 40 }) => `${size}px`};
  height: ${({ size = 40 }) => `${size}px`};
  margin: 0 auto;
  position: relative;

  @keyframes progress_spinner {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  span {
    background-color: #ffc476;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    animation: progress_spinner 1s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
  }
`;

export const WelcomePanel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const WelcomeInfo = styled.div``;

export const WelcomeTitle = styled.p`
  color: var(--caren-icongrey);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
`;
