import { z } from 'zod';
import { DoctorSchema } from './doctor';
import { PatientSchema } from './patient';

const TeleconsultationSchema = z.object({
  key: z.string(),
  anamnesis_key: z.string(),
  flow_concluded: z.boolean(),
  open: z.boolean(),
  room_opened_at: z.string().nullable(),
  room_closed_at: z.string().nullable(),
  room_duration_loaded_at: z.string().nullable(),
  room_patient_duration: z.number(),
  room_patient_start_time: z.string().nullable(),
  room_patient_end_time: z.string().nullable(),
  insurance_coverage: z.string().nullable(),
  expired: z.boolean(),
  concluded: z.boolean(),
  confirmed: z.boolean(),
  confirmation_at: z.string().nullable(),
  end_datetime: z.string(),
  scheduled_datetime: z.string(),
  cancellation_reason: z.string().nullable(),
  cancelled_at: z.string().nullable(),
  digital_signature_state: z.enum([
    'not_applicable',
    'pending',
    'signing',
    'complete'
  ]),
  evaluation_concluded: z.boolean(),
  has_report: z.boolean()
});

const ConsultSchema = z.object({
  key: z.string(),
  patient_name: z.string().nullable(),
  start_datetime: z.string(),
  end_datetime: z.string(),
  patient: PatientSchema.nullable(),
  employee: DoctorSchema.nullable(),
  teleconsultation: TeleconsultationSchema.nullable(),
  invitation: z.object({
    key: z.string(),
    accepted_at: z.string().nullable(),
    rejected_at: z.string().nullable(),
    scheduled_for: z.string(),
    insurance_partner: z.string().nullable(),
    email: z.string(),
    link: z.string().nullable(),
    free_trial: z.boolean().nullable()
  }),
  cancellation_reason: z.enum(['employee', 'patient', 'scheduled']).nullable(),
  cancelled_at: z.string().nullable()
});

const ConsultInviteSchema = z.object({
  email: z.string().email(),
  fiscal_code: z.string(),
  insurance_partner_key: z.string(),
  scheduled_for: z.string(),
  employee_key: z.string().optional()
});

const PatientFormSchema = z.object({
  insurance_partner_key: z.string({
    invalid_type_error: 'Selecione um plano de saúde válido',
    required_error: 'Selecione um plano de saúde'
  }),
  email: z
    .string({
      invalid_type_error: 'Digite um e-mail válido',
      required_error: 'Digite um e-mail'
    })
    .nonempty('Digite um e-mail válido')
    .email('Digite um e-mail válido')
    .toLowerCase(),
  fiscal_code: z
    .string({
      invalid_type_error: 'Digite um CPF válido',
      required_error: 'Digite um CPF'
    })
    .nonempty('Digite um CPF válido')
});

const TeleconsultationCompleteSchema = TeleconsultationSchema.merge(
  z.object({
    patient_name: z.string(),
    start_datetime: z.string().nullable(),
    doctor: DoctorSchema,
    patient: PatientSchema
  })
);

type PatientFormData = z.infer<typeof PatientFormSchema>;
type Consult = z.infer<typeof ConsultSchema>;
type Teleconsultation = z.infer<typeof TeleconsultationSchema>;
type ConsultInvite = z.infer<typeof ConsultInviteSchema>;

type TeleconsultationComplete = z.infer<typeof TeleconsultationCompleteSchema>;

export {
  ConsultSchema,
  TeleconsultationSchema,
  ConsultInviteSchema,
  PatientFormSchema
};
export type {
  Consult,
  Teleconsultation,
  TeleconsultationComplete,
  ConsultInvite,
  PatientFormData
};
