import React, { useState, useEffect, useRef } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ImageUploader from '../ImageUploader';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import { enableDoctorLinkInDoctorPanel } from '../../constants/featureToggles';
import CertVIDaasAlert from '../Modal/CertVIDaasAlert';

import {
  Container,
  ImageContainer,
  DoctorLogo,
  CameraIcon,
  InfoContainer,
  ImageContainerLoading,
  ImageContainerSpinner,
  DoctorName,
  DoctorSpec,
  NextPatientContainer,
  SeeMore,
  CTAOpenInvitationModal,
  AlertCert,
  AlertCertLogo,
  AlertCertTitle,
  AlertCertDescription,
  AlertCertCTA
} from './styles';
import { changeUserPictureService } from '../../services/user.service';
import { doctorGetSchedule } from '../../services/doctors.service';
import formattedSpecialties from '../../utils/formattedSpecialties';
import NextPatient from './NextPatient';
import { useAuth } from '../../hooks/AuthContext';
import InviteFooter from '../InviteFooter';
import getDoctorTitle from '../../utils/getDoctorTitle';

import logoVIDaas from '../../assets/images/logo-vidaas.png';
import { vidaasUserDiscovery } from '../../services/vidaas-user-discovery.service';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../v2/dialogs/consult/create';
import { type Consult } from '../../v2/entities/consult';
import { mutate } from 'swr';

type DoctorPanelProps = {
  inviteFriend?: boolean;
  onCreateConsult?: () => void;
  reloadTime?: number;
};

function DoctorPanel({
  inviteFriend = false,
  onCreateConsult,
  reloadTime = 0
}: DoctorPanelProps) {
  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);

  const history = useHistory();
  const { credentials, refreshSession } = useAuth();

  const { t } = useTranslation(['doctor_clinic']);

  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [showVIDaasAlert, setShowVIDaasAlert] = useState<boolean>(false);
  const [openVIDaasAlert, setOpenVIDaasAlert] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string | undefined>(
    credentials?.session?.avatar_url
  );
  const [firstAvailable, setFirstAvailable] = useState<Consult | null>(null);

  useEffect(() => {
    async function getVidaasUserDiscovery() {
      try {
        const { data } = await vidaasUserDiscovery();

        setShowVIDaasAlert(!data?.has_certificates);
      } catch (error) {
        // Not shown
      }
    }

    if (
      credentials?.session?.insurance_partners?.length != null &&
      credentials?.session?.insurance_partners?.length > 0
    ) {
      getVidaasUserDiscovery();
    }
  }, [credentials]);

  async function onDrop(newImage: Blob) {
    setLoading(true);

    try {
      const { data: picData } = await changeUserPictureService(newImage);

      await mutate('/employees/profile-completeness');

      toast(t('Foto atualizada com sucesso.'), {
        variant: 'success'
      });
      if (credentials) {
        refreshSession(credentials.token);
      }
      setProfileImage(picData.avatar_url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Not shown
    }
  }

  function maybeRenderPublicProfileLink() {
    if (enableDoctorLinkInDoctorPanel && credentials) {
      return (
        <Link
          to={`/medicos/${credentials.session.slug ?? credentials.session.key}`}
        >
          <SeeMore>{t('Ver perfil público')}</SeeMore>
        </Link>
      );
    }

    return null;
  }

  function handleToggleAlert() {
    setOpenVIDaasAlert(!openVIDaasAlert);
  }

  useEffect(() => {
    doctorGetSchedule({
      from: moment().format('YYYY-MM-DD'),
      to: moment().add(1, 'month').format('YYYY-MM-DD')
    }).then(response => {
      if (response.data.length > 0) {
        let consults = [];

        consults = response?.data?.filter(
          ({ end_datetime: date }: { end_datetime: string }) =>
            moment.utc(date).local().isAfter(moment())
        );

        consults = consults.sort(
          (a: { start_datetime?: string }, b: { start_datetime?: string }) =>
            new Date(a?.start_datetime ?? new Date()).getTime() -
            new Date(b?.start_datetime ?? new Date()).getTime()
        );

        const firstWaiting = consults[0];

        if (firstWaiting) {
          firstWaiting.confirmation_at = true;
          firstWaiting.scheduled_datetime = firstWaiting.start_datetime;

          setFirstAvailable(firstWaiting);
        }
      }
    });
  }, [reloadTime]);

  return (
    <Container>
      <ImageContainer
        data-tour="Antes de criar sua primeira teleconsulta, clique na câmera para inserir a sua foto de perfil."
        data-tour-index="2"
      >
        <DoctorLogo
          src={profileImage ?? credentials?.session?.avatar_url}
          title={credentials?.session.name}
          titleSize={45}
          backgroundStyle={{
            width: 124,
            height: 124,
            borderRadius: '100%',
            backgroundColor: 'var(--caren-image-fallback)'
          }}
        />

        <ImageUploader className="absolute bottom-0 right-0" onChange={onDrop}>
          <CameraIcon />
        </ImageUploader>

        <ImageContainerLoading className={loading ? 'active' : ''}>
          <ImageContainerSpinner>
            <span />
            <span />
          </ImageContainerSpinner>
        </ImageContainerLoading>
      </ImageContainer>

      <InfoContainer>
        <DoctorName>
          {getDoctorTitle(credentials?.session)} {credentials?.session.name}
        </DoctorName>

        <DoctorSpec>
          {formattedSpecialties(credentials?.session.specialties)}
        </DoctorSpec>

        {maybeRenderPublicProfileLink()}
      </InfoContainer>

      <NextPatientContainer
        isInHome={history.location.pathname === '/consultorio'}
      >
        <NextPatient firstAvailable={firstAvailable} />

        {inviteFriend && <InviteFooter />}
      </NextPatientContainer>

      <CTAOpenInvitationModal
        isInHome={history.location.pathname === '/consultorio'}
        data-tour="Clicando neste botão, você poderá realizar o agendamento para uma teleconsulta e enviar o convite para o seu paciente."
        data-tour-index="5"
      >
        <button
          type="button"
          title="Criar teleconsulta"
          aria-label="Criar teleconsulta"
          onClick={() => {
            createConsultDialogRef?.current?.open();
          }}
        >
          <SendIcon />
          <span>Criar teleconsulta</span>
        </button>
      </CTAOpenInvitationModal>

      {showVIDaasAlert && (
        <AlertCert>
          <AlertCertLogo>
            <img src={logoVIDaas} alt="Logo - VIDaaS" />
          </AlertCertLogo>

          <AlertCertTitle>
            Identificamos que você ainda não tem o Certificado Digital VIDaaS
          </AlertCertTitle>

          <AlertCertDescription>
            <p>
              *O Certificado Digital VIDaaS é indispensável para o registro da
              Teleconsulta no Prontuário Eletrônico do Paciente (PEP).
            </p>
          </AlertCertDescription>

          <AlertCertCTA>
            <button
              type="button"
              title="Saiba mais"
              aria-label="Saiba mais"
              onClick={handleToggleAlert}
            >
              <span>Saiba mais</span>
            </button>
          </AlertCertCTA>
        </AlertCert>
      )}
      <CertVIDaasAlert
        openVIDaasAlert={openVIDaasAlert}
        setOpenVIDaasAlert={setOpenVIDaasAlert}
      />
      <CreateConsultDialog
        ref={createConsultDialogRef}
        onSuccess={onCreateConsult}
      />
    </Container>
  );
}

export default DoctorPanel;
