import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useReducer
} from 'react';
import { Helmet } from 'react-helmet-async';
import {
  AssignmentTurnedIn,
  AssignmentLate,
  AccessTime
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../contexts/ToastContext';
import Header from '../../components/Header';
import {
  doctorCancelConsultationService,
  doctorDeleteInvitationService,
  doctorResendInvitationService
} from '../../services/doctors.service';
import UnreversibleConfirmationModal from '../../components/UnreversibleConfirmationModal';
import ContainerOpacity from '../../components/ContainerOpacity';
import UserSidebar from '../../components/Sidebar/UserSidebar';
import DoctorPanel from '../../components/DoctorPanel';
import { FORMAT } from '../../utils/moment/momentFormat';
import { momentUtcLocal } from '../../utils/moment/momentHelpers';
import Modal from '../../components/Modal';
import { teleconsultationReport } from '../../services/timeline_upload.service';
import { Table } from '../../v2/components/table';
import IconButton from '../../v2/components/inputs/buttons/IconButton';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/icons/clipboard-tick.svg';
import { ReactComponent as ClipboardErrorIcon } from '../../assets/icons/clipboard-close.svg';
import { ReactComponent as SmsIcon } from '../../assets/icons/sms-tracking.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as DocumentDownloadIcon } from '../../assets/icons/document-download.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/setting-5.svg';
import { ReactComponent as ReceiveIcon } from '../../assets/icons/receive-square.svg';
import { ReactComponent as TimerIcon } from '../../assets/icons/timer.svg';
import { getConsultations } from '../../services/consults';
import { type Consult } from '../../v2/entities/consult';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import Button from '../../v2/components/inputs/buttons/Button';
import CalendarInput from '../../v2/components/inputs/input/calendar';
import SelectInput from '../../v2/components/inputs/input/select';
import ConsultsReportsDialog, {
  type ConsultsReportsDialogHandles
} from '../../v2/dialogs/download/consults';
import SearchInput from '../../v2/components/inputs/input/search';
import SuccessStep from '../../v2/dialogs/consult/steps/success';
import SecretaryPanel from '../../components/SecretaryPanel';
import { useAuth } from '../../hooks/AuthContext';
import { ROLE_SECRETARY } from '../../routes/constants';
import { type Doctor } from '../../v2/entities/doctor';
import SelectDoctors from '../../v2/components/secretary/SelectDoctors';
import { Link } from 'react-router-dom';
import PersonImage from '../../v2/components/data/personImage';
import Tawk from '../../components/Tawk';
import Chatwoot from '../../components/Chatwoot';
import ExceededAppointmentsDialog, {
  type ExceededAppointmentsDialogHandles
} from '../../v2/dialogs/upgrade/exceededAppointments';
import { AxiosError } from 'axios';
import { useFreePlan } from '../../v2/hooks/plan/useFreePlan';

const CONSULTS_PEER_REQUEST = 20;

type FilterStateKeys =
  | 'search'
  | 'start'
  | 'end'
  | 'status'
  | 'insurance_partner';
type FilterState = Map<FilterStateKeys, string>;

type FilterAction = {
  type: 'UPDATE' | 'REMOVE' | 'RESET';
  property?: 'search' | 'start' | 'end' | 'status' | 'insurance_partner';
  value?: string;
};

function filterReducer(state: FilterState, action: FilterAction) {
  if (action.type === 'UPDATE' && action.property && action.value) {
    state.set(action.property, action.value);
    return new Map<FilterStateKeys, string>(state);
  } else if (action.type === 'REMOVE' && action.property) {
    state.delete(action.property);
    return new Map<FilterStateKeys, string>(state);
  } else if (action.type === 'RESET') {
    return new Map<FilterStateKeys, string>();
  }

  throw Error('Unknown action.');
}

export default function Schedule() {
  const { t } = useTranslation(['schedule']);
  const toast = useToast();
  const { credentials, refreshSession } = useAuth();
  const { data: freePlan } = useFreePlan();

  const isSecretary = credentials?.token?.user_type === ROLE_SECRETARY;

  const consultsReportsDialogRef = useRef<ConsultsReportsDialogHandles>(null);
  const exceededAppointmentsDialogRef =
    useRef<ExceededAppointmentsDialogHandles>(null);

  const [planFilters, setPlanFilters] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [actualPlanName, setActualPlanName] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allContentLoaded, setAllContentLoaded] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [tab, setTab] = useState<'upcoming' | 'historic'>('upcoming');
  const [consults, setConsults] = useState<null | Consult[]>(null);
  const [filters, filterDispatch] = useReducer(
    filterReducer,
    new Map<FilterStateKeys, string>()
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);

  const [unreversibleDialog, setUnreversibleDialog] = useState<{
    open: boolean;
    type: null | 'resend' | 'cancel' | 'delete';
    callback?: () => void;
  }>({
    open: false,
    type: null
  });
  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    link?: string;
  }>({
    open: false
  });

  const isConfirmed = (item: Consult) =>
    item.invitation.accepted_at !== null && item.teleconsultation !== null;
  const isFlowConcluded = (item: Consult) =>
    item.teleconsultation && item.teleconsultation.flow_concluded;
  const isEvaluatedAndSigned = (item: Consult) =>
    item.teleconsultation &&
    item.teleconsultation.evaluation_concluded &&
    item.teleconsultation.digital_signature_state !== 'pending';
  const isConcluded = (item: Consult) =>
    item.teleconsultation?.room_opened_at !== null &&
    item.teleconsultation?.room_closed_at !== null &&
    ((item.teleconsultation?.digital_signature_state === 'not_applicable' &&
      item.teleconsultation?.evaluation_concluded) ||
      (item.teleconsultation?.digital_signature_state === 'complete' &&
        item.teleconsultation?.evaluation_concluded));

  const isLoadedDuration = (item: Consult) =>
    !item.teleconsultation ||
    item.teleconsultation.room_duration_loaded_at !== null;
  const isCancelled = (item: Consult) =>
    item.cancelled_at !== null || item.cancellation_reason !== null;

  const getUnreversibleTitle = useCallback(() => {
    if (unreversibleDialog.type === 'delete')
      return t('Você tem certeza de que deseja cancelar esse convite?');

    if (unreversibleDialog.type === 'cancel')
      return t(
        'Você tem certeza de que deseja cancelar esta teleconsulta?\n\nIsto não pode ser desfeito.'
      );

    if (unreversibleDialog.type === 'resend')
      return t('Você tem certeza de que deseja reenviar esse convite?');

    return null;
  }, [t, unreversibleDialog.type]);

  const maybeDisableButton = (consult: Consult) => {
    const hasSecretaryFeature =
      selectedDoctor?.active_subscription?.plan.enable_secretaries;

    if (!isSecretary) return false;

    if (
      consult.invitation.insurance_partner ??
      consult?.teleconsultation?.insurance_coverage ??
      consult?.invitation.free_trial ??
      hasSecretaryFeature
    ) {
      return false;
    }

    return true;
  };

  const getCancelationReason = (consult: Consult) => {
    if (consult.cancelled_at === null || consult.cancellation_reason === null)
      return '';

    const cancelledDate = momentUtcLocal(consult.cancelled_at).format(
      FORMAT['pt-datetime']
    );

    switch (consult.cancellation_reason) {
      case 'employee':
        return `Cancelada pelo médico em ${cancelledDate}`;
      case 'patient':
        return `Recusada pelo paciente em ${cancelledDate}`;
      default:
        return `Cancelada pelo sistema em ${cancelledDate}`;
    }
  };

  const getPendingReason = (consult: Consult) => {
    if (!consult.teleconsultation) return '';

    if (!consult.teleconsultation.evaluation_concluded)
      return 'Avaliação pendente';

    if (
      consult.teleconsultation.evaluation_concluded &&
      consult.teleconsultation.digital_signature_state === 'pending'
    )
      return 'Assinatura pendente';

    return '';
  };

  const getDuration = (consult: Consult) => {
    if (!consult.teleconsultation) return '';

    if (consult.cancelled_at !== null) return '';

    if (!consult.teleconsultation.room_patient_duration)
      return 'Paciente não entrou';

    const minutes = Math.floor(
      consult.teleconsultation.room_patient_duration / 60
    );

    if (minutes <= 0)
      return `${consult.teleconsultation.room_patient_duration} seg`;

    return `${minutes} min`;
  };

  const downloadConsultReport = async (key: string) => {
    try {
      const data = await teleconsultationReport(key);

      window.location.href = data.url;
    } catch (error) {
      toast(t('Ops, não foi possível carregar o relatório...'), {
        variant: 'error'
      });
    }
  };

  const copyUrlToClipboard = (link: string, success: string) => {
    navigator.clipboard.writeText(`${window.location.origin}${link}`);
    toast(t(success), {
      variant: 'success'
    });
  };

  const resendInvitation = async (key: string) => {
    try {
      const { link } = await doctorResendInvitationService(key, selectedDoctor);

      toast(t('Convite reenviado com sucesso.'), {
        variant: 'success'
      });

      setConfirmationDialog({ open: true, link });
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;

        const exceededMessage = 'You have exceeded the free trial limit';
        const responseMessage = response?.data?.error?.message;

        if (response?.status === 403 && responseMessage === exceededMessage) {
          exceededAppointmentsDialogRef.current?.open();
          return;
        }
      }

      toast(t('Ops, houve um erro no reenvio do convite...'), {
        variant: 'error'
      });
    }
  };

  const cancelConsult = async (
    invitationKey: string,
    teleconsultationKey?: string
  ) => {
    try {
      let deletedConsultation: any = null;

      if (teleconsultationKey) {
        deletedConsultation = await doctorCancelConsultationService(
          teleconsultationKey,
          selectedDoctor
        );
      } else {
        deletedConsultation = await doctorDeleteInvitationService(
          invitationKey,
          selectedDoctor
        );
      }

      if (!deletedConsultation) throw Error();

      setConsults(null);

      toast(
        t(
          teleconsultationKey
            ? 'Consulta cancelada com sucesso.'
            : 'Agendamento deletado com sucesso.'
        ),
        {
          variant: 'success'
        }
      );
    } catch (error) {
      toast(
        t(
          teleconsultationKey
            ? 'Ops, houve um erro no cancelamento...'
            : 'Ops, houve um erro na exclusão...'
        ),
        {
          variant: 'error'
        }
      );
    }
  };

  const getConsults = useCallback(async () => {
    if (consults === null) {
      if (isSecretary && selectedDoctor === null) return;

      setLoading(true);
      setAllContentLoaded(false);

      const result = await getConsultations(
        tab,
        Object.fromEntries(filters),
        selectedDoctor
      );
      setConsults(result);

      setLoading(false);
    }
  }, [consults, tab, filters, isSecretary, selectedDoctor]);

  const loadMoreConsults = useCallback(async () => {
    if (consults === null || consults.length <= 0) return;
    if (isSecretary && selectedDoctor === null) return;

    setLoadingMore(true);

    const lastKey = consults[consults.length - 1].key;

    const result = await getConsultations(
      tab,
      Object.fromEntries(filters),
      selectedDoctor,
      CONSULTS_PEER_REQUEST,
      lastKey
    );

    if (result.length <= 0) {
      setAllContentLoaded(true);
    } else {
      setConsults(old => (old ? old.concat(result) : null));
    }

    setLoadingMore(false);
  }, [consults, tab, filters, isSecretary, selectedDoctor]);

  const getFilteredResults = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }

    setTimer(
      setTimeout(() => {
        setConsults(null);
      }, 500)
    );
  }, [timer]);

  const createPlanFilters = () => {
    if (isSecretary && selectedDoctor === null) return;

    let filterPlans: Array<{ label: string; value: string }> = [];
    let subscription: any;
    let plans: any;

    if (isSecretary) {
      subscription = selectedDoctor?.active_subscription;
      plans = selectedDoctor?.insurance_partners;
    } else {
      subscription = credentials?.session.active_subscription;
      plans = credentials?.session.insurance_partners;
    }

    filterPlans =
      plans.map((item: any) => {
        return { label: item.name, value: item.code };
      }) ?? [];

    if (subscription && subscription.status === 'active') {
      setActualPlanName(subscription.plan.name);
      filterPlans.push({
        label: subscription.plan.name,
        value: 'private'
      });
    }
    setPlanFilters(filterPlans);
  };

  async function handleCopyLinkToAnamnesis(schedule: Consult) {
    if (!schedule) {
      toast(t('Ops, houve um erro ao gerar novo o novo link...'), {
        variant: 'error'
      });

      return;
    }

    const { link } = await doctorResendInvitationService(
      schedule.invitation.key,
      null,
      false
    );

    navigator.clipboard.writeText(link);
    toast(
      t(
        'Link para preenchimento da anamnese copiado! Favor enviar ao paciente.'
      ),
      {
        variant: 'success'
      }
    );
  }

  useEffect(() => {
    getConsults();
  }, [getConsults]);

  useEffect(() => {
    createPlanFilters();
  }, [selectedDoctor, isSecretary]);

  useEffect(() => {
    if (credentials?.token) {
      refreshSession(credentials.token);
    }
  }, []);

  const validateFreeTrialAppointment = (
    appointmentInsurancePartner: string | null
  ) => {
    if (appointmentInsurancePartner != null) return true;

    if (freePlan) {
      return (
        credentials?.session?.active_subscription?.plan
          ?.enable_private_teleconsultation_invitations === true ||
        freePlan?.used < freePlan?.total
      );
    }

    return false;
  };

  const renderWaitingRoomIconButton = (item: Consult) => {
    const valid = validateFreeTrialAppointment(
      item.invitation.insurance_partner
    );

    if (valid) {
      return (
        <IconButton
          title="Sala de Espera"
          disabled={item.teleconsultation === null}
          href={`/consultas/${item.teleconsultation?.key}/sala-de-espera`}
          icon={CameraIcon}
          type="primary"
        />
      );
    }

    return (
      <IconButton
        title="Sala de Espera"
        disabled={item.teleconsultation === null}
        onClick={() => {
          exceededAppointmentsDialogRef.current?.open();
        }}
        icon={CameraIcon}
        type="primary"
      />
    );
  };

  return (
    <>
      <Helmet title="Agenda - Caren"></Helmet>
      <ContainerOpacity />
      <UserSidebar />
      <Header />
      <div className="flex flex-col items-center gap-16 px-6 py-12 xl:flex-row xl:items-start xl:px-10">
        <div className="w-full max-w-xs shrink-0 xl:w-64 xl:max-w-none">
          {isSecretary ? (
            <SecretaryPanel
              onResendClick={key => {
                setUnreversibleDialog({
                  open: true,
                  type: 'resend',
                  callback: async () => {
                    await resendInvitation(key);
                  }
                });
              }}
            />
          ) : (
            <DoctorPanel />
          )}
        </div>

        {isSecretary && selectedDoctor === null ? (
          <div className="w-full flex-grow">
            <SelectDoctors
              onSelectDoctor={doctor => {
                setSelectedDoctor(doctor);
              }}
            />
          </div>
        ) : (
          <div className="flex w-full flex-grow flex-col items-stretch">
            {isSecretary && selectedDoctor && (
              <button
                type="button"
                onClick={() => {
                  if (tab === 'historic') {
                    setTab('upcoming');
                    setShowFilters(false);
                    filterDispatch({ type: 'RESET' });
                  }

                  setConsults(null);
                  setSelectedDoctor(null);
                }}
                className="mb-10 flex w-full cursor-pointer flex-col items-center gap-y-4 rounded-md bg-white p-4 font-nunito md:w-fit"
              >
                <div className="flex items-center gap-x-2 md:w-full md:self-start">
                  <div className="h-12 w-12">
                    <PersonImage
                      name={selectedDoctor.name}
                      image={selectedDoctor.avatar_url}
                    />
                  </div>
                  <p className="text-base font-semibold text-blue-650">
                    {selectedDoctor.name}
                  </p>
                </div>
                <span className="text-xs text-gray-250">
                  Clique aqui para trocar de profissional
                </span>
              </button>
            )}

            <Tab.Group
              onChange={index => {
                if (index === 0 && tab === 'historic') {
                  setTab('upcoming');
                  setConsults(null);
                  setShowFilters(false);
                  filterDispatch({ type: 'RESET' });
                } else if (index === 1 && tab === 'upcoming') {
                  setTab('historic');
                  setConsults(null);
                }
              }}
            >
              <div className="mb-7 flex w-full flex-col items-stretch justify-between md:flex-row md:items-center">
                <Tab.List className="mb-5 flex w-full items-end border-b border-yellow-650 sm:w-fit md:mb-0">
                  <Tab className="w-full border-b-2 pb-4 pr-6 font-nunito text-lg font-semibold outline-none transition-all duration-200 ui-selected:border-yellow-650 ui-selected:text-blue-650 ui-not-selected:border-b-transparent ui-not-selected:text-blue-650/50 sm:w-fit">
                    Próximas Consultas
                  </Tab>
                  <Tab className="w-full border-b-2 pb-4 pr-6 font-nunito text-lg font-semibold outline-none transition-all duration-200 ui-selected:border-yellow-650 ui-selected:text-blue-650 ui-not-selected:border-b-transparent ui-not-selected:text-blue-650/50 sm:w-fit">
                    Histórico
                  </Tab>
                </Tab.List>
                <div className="flex w-full flex-col items-stretch gap-x-5 gap-y-4 sm:w-fit sm:flex-row sm:items-center">
                  {!showFilters && (
                    <div className="w-full sm:w-64">
                      <SearchInput
                        onUpdateValue={value => {
                          if (value.length > 0) {
                            filterDispatch({
                              type: 'UPDATE',
                              property: 'search',
                              value
                            });
                          } else if (
                            value.length <= 0 &&
                            filters.has('search')
                          ) {
                            filterDispatch({
                              type: 'REMOVE',
                              property: 'search'
                            });
                          }

                          getFilteredResults();
                        }}
                      />
                    </div>
                  )}
                  {tab === 'historic' && (
                    <Button
                      label={showFilters ? 'Limpar filtros' : 'Filtros'}
                      title={
                        showFilters
                          ? 'Voltar para pesquisa padrão'
                          : 'Abrir filtros avançados'
                      }
                      onClick={() => {
                        if (!showFilters) {
                          setShowFilters(true);
                        } else {
                          setShowFilters(false);
                          filterDispatch({ type: 'RESET' });
                          getFilteredResults();
                        }
                      }}
                      icon={SettingsIcon}
                    />
                  )}
                </div>
              </div>
              {showFilters && tab === 'historic' && (
                <div className="mb-7 flex w-full flex-wrap items-end gap-5 lg:flex-nowrap lg:gap-x-3 2xl:gap-x-7">
                  <div className="w-full sm:max-w-[16rem]">
                    <SearchInput
                      label="Busca:"
                      defaultValue={filters.get('search') ?? ''}
                      onUpdateValue={value => {
                        if (value.length > 0) {
                          filterDispatch({
                            type: 'UPDATE',
                            property: 'search',
                            value
                          });
                        } else if (value.length <= 0 && filters.has('search')) {
                          filterDispatch({
                            type: 'REMOVE',
                            property: 'search'
                          });
                        }

                        getFilteredResults();
                      }}
                    />
                  </div>
                  <div className="w-full sm:max-w-[11rem]">
                    <CalendarInput
                      label="Período Inicial:"
                      value={filters.get('start') ?? ''}
                      onChange={e => {
                        if (e.target.value.length > 0) {
                          filterDispatch({
                            type: 'UPDATE',
                            property: 'start',
                            value: e.target.value
                          });
                        } else if (
                          e.target.value.length <= 0 &&
                          filters.has('start')
                        ) {
                          filterDispatch({
                            type: 'REMOVE',
                            property: 'start'
                          });
                        }

                        getFilteredResults();
                      }}
                    />
                  </div>
                  <div className="w-full sm:max-w-[11rem]">
                    <CalendarInput
                      label="Período Final:"
                      value={filters.get('end') ?? ''}
                      min={
                        filters.has('start')
                          ? moment(filters.get('start')).format('YYYY-MM-DD')
                          : undefined
                      }
                      onChange={e => {
                        if (e.target.value.length > 0) {
                          filterDispatch({
                            type: 'UPDATE',
                            property: 'end',
                            value: e.target.value
                          });
                        } else if (
                          e.target.value.length <= 0 &&
                          filters.has('end')
                        ) {
                          filterDispatch({
                            type: 'REMOVE',
                            property: 'end'
                          });
                        }

                        getFilteredResults();
                      }}
                    />
                  </div>
                  <div className="w-full sm:max-w-[13rem]">
                    <SelectInput
                      label="Plano:"
                      defaultOption={filters.get('insurance_partner') ?? ''}
                      options={planFilters}
                      onSelectOption={option => {
                        if (option !== null) {
                          filterDispatch({
                            type: 'UPDATE',
                            property: 'insurance_partner',
                            value: option.value
                          });
                        } else if (
                          option === null &&
                          filters.has('insurance_partner')
                        ) {
                          filterDispatch({
                            type: 'REMOVE',
                            property: 'insurance_partner'
                          });
                        }

                        getFilteredResults();
                      }}
                    />
                  </div>
                  <div className="w-full sm:max-w-[13rem]">
                    <SelectInput
                      label="Situação:"
                      defaultOption={filters.get('status') ?? ''}
                      options={[
                        {
                          label: 'Todas',
                          value: ''
                        },
                        {
                          label: 'Pendente',
                          value: 'pending'
                        },
                        {
                          label: 'Concluídas',
                          value: 'concluded'
                        }
                      ]}
                      onSelectOption={option => {
                        if (option !== null) {
                          filterDispatch({
                            type: 'UPDATE',
                            property: 'status',
                            value: option.value
                          });
                        } else if (option === null && filters.has('status')) {
                          filterDispatch({
                            type: 'REMOVE',
                            property: 'status'
                          });
                        }

                        getFilteredResults();
                      }}
                    />
                  </div>
                  <Button
                    label="Gerar relatório"
                    title="Clique para fazer o download do relatório"
                    onClick={() =>
                      consultsReportsDialogRef.current?.open({
                        tab,
                        ...Object.fromEntries(filters)
                      })
                    }
                    icon={ReceiveIcon}
                    colors="yellow"
                    type="button"
                  />
                </div>
              )}
              <Tab.Panels>
                <Tab.Panel>
                  <Table.Root>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell.Header title="Paciente" />
                        <Table.Cell.Header title="Data" />
                        <Table.Cell.Header title="Status" />
                        <Table.Cell.Header title="Anamnese" />
                        <Table.Cell.Header title="Ações" />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!loading && consults && consults.length <= 0 && (
                        <Table.Row>
                          <td colSpan={4} className="p-5 text-center">
                            <p className="font-nunito text-base text-gray-250">
                              Sem Resultados
                            </p>
                          </td>
                        </Table.Row>
                      )}
                      {!loading &&
                        consults &&
                        React.Children.toArray(
                          consults.map(item => (
                            <Table.Row>
                              {item.patient?.key ? (
                                <Link to={`/pacientes/${item.patient?.key}`}>
                                  <Table.Cell.Person
                                    name={
                                      item.patient_name ?? item.invitation.email
                                    }
                                    image={item.patient?.avatar_url}
                                  />
                                </Link>
                              ) : (
                                <Table.Cell.Person
                                  name={
                                    item.patient_name ?? item.invitation.email
                                  }
                                  image={item.patient?.avatar_url}
                                />
                              )}
                              <Table.Cell.Text
                                label="Data"
                                text={momentUtcLocal(
                                  item.start_datetime
                                ).format(FORMAT['pt-datetime'])}
                              />
                              <Table.Cell.Text
                                label="Status"
                                text={
                                  isConfirmed(item) ? 'Confirmado' : 'Pendente'
                                }
                                className={`font-semibold ${
                                  isConfirmed(item)
                                    ? 'text-success'
                                    : 'text-gray-400'
                                }`}
                              />
                              <Table.Cell.Actions
                                actions={[
                                  !item.teleconsultation && (
                                    <IconButton
                                      title={
                                        'Aguardando o paciente aceitar o convite'
                                      }
                                      disabled
                                      icon={AccessTime}
                                    />
                                  ),
                                  isFlowConcluded(item) ? (
                                    <>
                                      {isSecretary ? (
                                        <IconButton
                                          title={
                                            'Copiar link para visualização da anamnese (apenas para o profissional de saúde)'
                                          }
                                          disabled={
                                            item.teleconsultation === null ||
                                            maybeDisableButton(item)
                                          }
                                          onClick={() => {
                                            copyUrlToClipboard(
                                              `/consultas/${item.teleconsultation?.key}/anamnese/${item.teleconsultation?.anamnesis_key}`,
                                              'Link para preenchimento da anamnese copiado! Favor enviar ao profissional de saúde.'
                                            );
                                          }}
                                          icon={AssignmentTurnedIn}
                                          type="success"
                                        />
                                      ) : (
                                        <IconButton
                                          title={'Visualizar Anamnese'}
                                          href={`/consultas/${item.teleconsultation?.key}/anamnese/${item.teleconsultation?.anamnesis_key}`}
                                          icon={AssignmentTurnedIn}
                                          type="success"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item.teleconsultation && (
                                        <IconButton
                                          title={
                                            'Copiar link para preenchimento da anamnese'
                                          }
                                          disabled={
                                            item.teleconsultation === null ||
                                            maybeDisableButton(item)
                                          }
                                          onClick={() => {
                                            handleCopyLinkToAnamnesis(item);
                                          }}
                                          icon={AssignmentLate}
                                          type="warning"
                                        />
                                      )}
                                    </>
                                  )
                                ]}
                              />
                              <Table.Cell.Actions
                                actions={[
                                  isSecretary ? (
                                    <IconButton
                                      title={'Copiar link da sala de espera'}
                                      disabled={
                                        item.teleconsultation === null ||
                                        maybeDisableButton(item)
                                      }
                                      onClick={() => {
                                        copyUrlToClipboard(
                                          `/consultas/${item.teleconsultation?.key}/sala-de-espera`,
                                          'Link para da sala de espera copiado!'
                                        );
                                      }}
                                      icon={CameraIcon}
                                      type="primary"
                                    />
                                  ) : (
                                    renderWaitingRoomIconButton(item)
                                  ),
                                  <IconButton
                                    title="Reenviar Convite"
                                    onClick={() => {
                                      setUnreversibleDialog({
                                        open: true,
                                        type: 'resend',
                                        callback: async () => {
                                          await resendInvitation(
                                            item.invitation.key
                                          );
                                        }
                                      });
                                    }}
                                    icon={SmsIcon}
                                    disabled={maybeDisableButton(item)}
                                  />,
                                  <IconButton
                                    title={
                                      isConfirmed(item)
                                        ? 'Cancelar Consulta'
                                        : 'Deletar Convite'
                                    }
                                    onClick={() => {
                                      setUnreversibleDialog({
                                        open: true,
                                        type: isConfirmed(item)
                                          ? 'cancel'
                                          : 'delete',
                                        callback: () => {
                                          cancelConsult(
                                            item.invitation.key,
                                            item.teleconsultation?.key
                                          );
                                        }
                                      });
                                    }}
                                    icon={TrashIcon}
                                    disabled={maybeDisableButton(item)}
                                  />
                                ]}
                              />
                            </Table.Row>
                          ))
                        )}
                      {loading && consults === null && (
                        <>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                        </>
                      )}
                    </Table.Body>
                  </Table.Root>
                </Tab.Panel>
                <Tab.Panel>
                  <Table.Root>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell.Header title="Paciente" />
                        <Table.Cell.Header title="Data" />
                        <Table.Cell.Header title="Status" />
                        <Table.Cell.Header title="Plano" />
                        <Table.Cell.Header title="Duração" />
                        {!isSecretary && <Table.Cell.Header title="Ações" />}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {!loading && consults && consults.length <= 0 && (
                        <Table.Row>
                          <td colSpan={5} className="p-5 text-center">
                            <p className="font-nunito text-base text-gray-250">
                              Sem Resultados
                            </p>
                          </td>
                        </Table.Row>
                      )}
                      {!loading &&
                        consults &&
                        React.Children.toArray(
                          consults.map(item => (
                            <Table.Row>
                              {item.patient?.key ? (
                                <Link to={`/pacientes/${item.patient?.key}`}>
                                  <Table.Cell.Person
                                    name={
                                      item.patient_name ?? item.invitation.email
                                    }
                                    image={item.patient?.avatar_url}
                                  />
                                </Link>
                              ) : (
                                <Table.Cell.Person
                                  name={
                                    item.patient_name ?? item.invitation.email
                                  }
                                  image={item.patient?.avatar_url}
                                />
                              )}
                              <Table.Cell.Text
                                label="Data"
                                text={momentUtcLocal(
                                  item.teleconsultation
                                    ?.room_patient_start_time ??
                                    item.start_datetime
                                ).format(FORMAT['pt-datetime'])}
                              />
                              <Table.Cell.Text
                                label="Status"
                                text={
                                  isCancelled(item)
                                    ? getCancelationReason(item)
                                    : isConcluded(item)
                                    ? 'Concluída'
                                    : getPendingReason(item)
                                }
                                className={`font-semibold ${
                                  isCancelled(item) || !isConcluded(item)
                                    ? 'text-warning'
                                    : 'text-success'
                                }`}
                              />
                              <Table.Cell.Text
                                label="Plano"
                                text={
                                  item?.invitation?.insurance_partner ??
                                  actualPlanName ??
                                  'Consulta particular'
                                }
                              />
                              <Table.Cell.Text
                                label="Duração"
                                text={
                                  isLoadedDuration(item)
                                    ? getDuration(item)
                                    : 'Registrando Chamada...'
                                }
                              />
                              {!isSecretary && (
                                <Table.Cell.Actions
                                  actions={[
                                    <IconButton
                                      title={'Avaliação'}
                                      target="_blank"
                                      href={`/pos-atendimento/${item.teleconsultation?.key}`}
                                      disabled={
                                        item.teleconsultation === null ||
                                        isCancelled(item)
                                      }
                                      type={
                                        isEvaluatedAndSigned(item)
                                          ? 'success'
                                          : 'neutral'
                                      }
                                      icon={
                                        isEvaluatedAndSigned(item)
                                          ? ClipboardIcon
                                          : ClipboardErrorIcon
                                      }
                                    />,
                                    <IconButton
                                      title="Baixar Relatório"
                                      onClick={() => {
                                        if (item.teleconsultation) {
                                          downloadConsultReport(
                                            item.teleconsultation.key
                                          );
                                        }
                                      }}
                                      disabled={
                                        !item.teleconsultation?.has_report
                                      }
                                      type={
                                        item.teleconsultation?.has_report
                                          ? 'success'
                                          : 'neutral'
                                      }
                                      icon={DocumentDownloadIcon}
                                    />
                                  ]}
                                />
                              )}
                            </Table.Row>
                          ))
                        )}
                      {loading && consults === null && (
                        <>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                            <Table.Cell.Loading />
                          </Table.Row>
                        </>
                      )}
                    </Table.Body>
                  </Table.Root>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            {loadingMore && (
              <div className="mt-10 flex animate-pulse items-center gap-x-4 self-center text-gray-450">
                <TimerIcon className="h-6 w-6 animate-spin" />
                <p className="font-nunito">Carregando mais resultados...</p>
              </div>
            )}

            {consults &&
              consults.length > 0 &&
              consults.length % CONSULTS_PEER_REQUEST === 0 &&
              !allContentLoaded && (
                <div className="mt-10 w-full max-w-[14rem] self-center">
                  <Button
                    type="button"
                    onClick={async () => {
                      await loadMoreConsults();
                    }}
                    disabled={loadingMore}
                    label="Mostrar mais"
                    colors="yellow"
                    size="large"
                  />
                </div>
              )}
          </div>
        )}

        <UnreversibleConfirmationModal
          title={getUnreversibleTitle()}
          openModal={unreversibleDialog.open}
          setOpenModal={() => {
            setUnreversibleDialog({ open: false, type: null });
          }}
          handleConfirmation={(status: boolean) => {
            if (status && unreversibleDialog.callback !== undefined) {
              unreversibleDialog.callback();
            }
            setUnreversibleDialog({ open: false, type: null });
          }}
          cancelButtonText="Não"
          confirmButtonText="Sim"
        />
      </div>

      <Modal
        handleOpenModal={() => {
          setConfirmationDialog({ open: false });
        }}
        openModal={confirmationDialog.open}
        modalTitle={t('Convite Reenviado!')}
        modalTitleColor="black"
        containerStyles={{ textAlign: 'center' }}
        modalWidth="760px"
        noClosingOutside={true}
      >
        <SuccessStep
          invitationLink={confirmationDialog.link ?? ''}
          onClose={() => {
            setConfirmationDialog({ open: false });
          }}
        />
      </Modal>

      <ConsultsReportsDialog
        employeeKey={selectedDoctor?.key}
        ref={consultsReportsDialogRef}
      />

      <ExceededAppointmentsDialog ref={exceededAppointmentsDialogRef} />
      <Tawk />
      <Chatwoot />
    </>
  );
}
