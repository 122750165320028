import { z } from 'zod';

const PatientSchema = z.object({
  key: z.string(),
  name: z.string(),
  fiscal_code: z.string(),
  avatar_url: z.string().nullable()
});

const PatientCreateSchema = z
  .object({
    name: z
      .string({ required_error: 'Digite seu nome e sobrenome' })
      .trim()
      .nonempty('Digite seu nome e sobrenome')
      .regex(/[A-Za-z]\s+[A-Za-z]/g, 'Por favor informe nome e sobrenome'),
    email: z
      .string({ required_error: 'Digite um e-mail' })
      .trim()
      .nonempty('Digite um e-mail')
      .email('Digite um e-mail válido'),
    fiscal_code: z
      .string({ required_error: 'Digite um CPF' })
      .nonempty('Digite um CPF')
      .regex(
        /\d{3}\.\d{3}\.\d{3}-\d{2}/,
        'Digite o CPF no formato: xxx.xxx.xxx-xx'
      ),
    gender: z.enum(['female', 'male'], {
      errorMap: () => ({ message: 'Selecione um sexo biológico' })
    }),
    birthdate: z.union([
      z.date({
        errorMap: () => ({ message: '' })
      }),
      z.string().regex(/\d{4}-\d{2}-\d{2}/)
    ]),
    phone: z
      .string({ required_error: 'Digite um telefone' })
      .nonempty('Digite um telefone')
      .regex(
        /\(\d{2}\)\s\d{5}-\d{4}/,
        'Digite o telefone no formato: (xx) xxxxx-xxxx'
      ),
    accept_terms: z
      .boolean({
        required_error:
          'É necessário aceitar os Termos de Uso e Políticas de Privacidade'
      })
      .refine(
        value => value,
        'É necessário aceitar os Termos de Uso e Políticas de Privacidade'
      ),
    password: z
      .string({ required_error: 'Digite uma senha válida' })
      .nonempty('Digite uma senha válida')
      .min(8, 'Pelo menos 8 caracteres')
      .regex(/[0-9]/g, 'Digite pelo menos 1 caractere numérico')
      .regex(/[A-Za-z]/g, 'Digite pelo menos 1 caractere alfabético'),
    password_confirmation: z
      .string({ required_error: 'Digite uma senha válida' })
      .nonempty('Digite uma senha válida')
      .min(8, 'Digite pelo menos 8 caracteres')
      .regex(/[0-9]/g, 'Digite pelo menos 1 caractere numérico')
      .regex(/[A-Za-z]/g, 'Digite pelo menos 1 caractere alfabético')
  })
  .refine(data => data.password === data.password_confirmation, {
    message: 'Digite a mesma senha do campo anterior',
    path: ['password_confirmation']
  });

type Patient = z.infer<typeof PatientSchema>;
type PatientCreate = z.infer<typeof PatientCreateSchema>;

export { PatientSchema, PatientCreateSchema };
export type { Patient, PatientCreate };
