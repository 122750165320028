import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import { TextField, Chip, InputAdornment, MenuItem } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../../contexts/ToastContext';
import { useAuth } from '../../../../hooks/AuthContext';
import LoadingComponent from '../../../Loading';
import Modal from '../../../Modal';
import {
  FormContainer,
  UpdateProfileButton,
  ErrorMessageText
} from './form.styles';
import { updateProfileService } from '../../../../services/session';
import nthIndexOf from '../../../../utils/nthIndexOf';
import { availableCountries } from '../../../../utils/countryLocaleUtils';
import { stateLabels } from '../../../../utils/brazilStateUtils';
import { DOCTOR_OFFICE_CODE } from '../../../../constants/common';
import { useSpecialties } from '../../../../v2/hooks/specialties/useSpecialties';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type DoctorProfile,
  DoctorProfileSchema
} from '../../../../v2/entities/profile';
import AccountCompleteChecklist from './AccountCompleteChecklist';
import { useProfileCompleteness } from '../../../../v2/hooks/user/useProfileCompleteness';
import history from '../../../../services/history';
// import CRMUpload from './CRMUpload';

export default function DataForm() {
  const { credentials, refreshSession } = useAuth();

  const { data: profileCompleteness, mutate: profileCompletenessMutate } =
    useProfileCompleteness();

  const [lastProfileCompleteness, setLastProfileCompleteness] = useState<
    number | null
  >(null);

  const telemedOffice = credentials?.session.offices?.find(
    item => item.code === DOCTOR_OFFICE_CODE
  );

  const splitSlug = () => {
    if (!credentials?.session.slug) return ['', ''];

    const splitPos = nthIndexOf(credentials.session.slug, '-', 2) + 1;
    return [
      credentials.session.slug.substring(0, splitPos),
      credentials.session.slug.substring(splitPos)
    ];
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<DoctorProfile>({
    defaultValues: credentials
      ? {
          display_name: credentials.session.name,
          slug: splitSlug()[1],
          phone: credentials.session.phone,
          bio: credentials.session.bio,
          fiscal_code: credentials.session.fiscal_code?.replace(
            /(\d{3})([0-9]{3})([0-9]{3})(\d{2})/,
            (_, first, second, third, four) => {
              return `${first}.${second}.${third}-${four}`;
            }
          ),
          doctor_profile: {
            specialties: credentials.session.specialties ?? [],
            professional_code: credentials.session.professional_code?.replace(
              /^(CRM\s*)/,
              ''
            ),
            professional_code_region:
              credentials.session.professional_code_region ?? 'ac',
            office: {
              country: telemedOffice?.country ?? undefined,
              state: telemedOffice?.state,
              city: telemedOffice?.city,
              name: telemedOffice?.name,
              street_name: telemedOffice?.street_name,
              complement: telemedOffice?.complement ?? undefined
            }
          }
        }
      : undefined,
    resolver: zodResolver(DoctorProfileSchema)
  });

  const { data: specialties } = useSpecialties();

  const { t } = useTranslation(['doctor_profile']);

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  function handleOpenModal() {
    setOpenModal(!openModal);
  }

  async function handleUpdateProfile(data: DoctorProfile) {
    const formData = data;

    formData.doctor_profile.office.code = DOCTOR_OFFICE_CODE;

    setLoading(true);

    try {
      await updateProfileService(formData);

      if (credentials) {
        refreshSession(credentials.token);
      }

      await profileCompletenessMutate();

      toast(t('Perfil atualizado com sucesso!'), {
        variant: 'success'
      });
    } catch (error) {
      toast(t('Verifique se os campos estão preenchidos corretamente.'), {
        variant: 'error'
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      profileCompleteness &&
      lastProfileCompleteness !== profileCompleteness.profile_completeness
    ) {
      if (
        lastProfileCompleteness !== null &&
        lastProfileCompleteness < 100 &&
        profileCompleteness.profile_completeness === 100
      ) {
        history.push('/');
      }

      setLastProfileCompleteness(profileCompleteness.profile_completeness);
    }
  }, [profileCompleteness, lastProfileCompleteness]);

  return (
    <>
      <Modal
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        modalTitle={t('Alterar senha?')}
        modalWidth="80%"
        modalTitleColor={undefined}
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
      />
      {profileCompleteness &&
        profileCompleteness.profile_completeness < 100 && (
          <AccountCompleteChecklist />
        )}
      <FormContainer
        className="mt-4"
        onSubmit={handleSubmit(handleUpdateProfile)}
      >
        <TextField
          label={t('Nome de exibição')}
          type="text"
          variant="outlined"
          fullWidth
          {...register('display_name')}
        />
        <div className="flex w-full items-center justify-center">
          <TextField
            label={t('Link único:')}
            type="text"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {splitSlug()[0]}
                </InputAdornment>
              )
            }}
            {...register('slug')}
          />
        </div>
        <ErrorMessage errors={errors} name="slug" as={<ErrorMessageText />} />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, ref, value } }) => (
            <InputMask
              mask="(99) 99999-9999"
              type="text"
              onChange={e => {
                onChange(e);
              }}
              defaultValue={value}
            >
              <TextField
                label={t('Celular')}
                fullWidth
                variant="outlined"
                inputRef={ref}
              />
            </InputMask>
          )}
        />
        <Controller
          control={control}
          render={fields => (
            <Autocomplete
              {...fields.field}
              multiple
              options={specialties ?? []}
              getOptionLabel={option => `${option.name}`}
              onChange={(e, specialty) => {
                setValue('doctor_profile.specialties', specialty);
              }}
              renderTags={(values, getTagProps) => {
                const sortedValues = [...values].sort(
                  (specialty1, specialty2) => {
                    if (specialty1.name < specialty2.name) return -1;
                    if (specialty1.name > specialty2.name) return 1;
                    return 0;
                  }
                );

                return (
                  <div className="max-h-[120px] overflow-auto">
                    {React.Children.toArray(
                      sortedValues.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    )}
                  </div>
                );
              }}
              renderInput={params => (
                <TextField
                  label={t('Especialidades')}
                  variant="outlined"
                  inputRef={fields.field.ref}
                  {...params}
                />
              )}
            />
          )}
          name="doctor_profile.specialties"
          defaultValue={credentials?.session?.specialties}
          rules={{
            validate: specialtiesArray => {
              if (specialtiesArray && specialtiesArray.length > 3) {
                return t('No máximo 3 especialidades').toString();
              }

              return true;
            }
          }}
        />
        <ErrorMessage
          errors={errors}
          name="doctor_profile.specialties"
          as={<ErrorMessageText />}
        />
        <TextField
          label={t('Currículo Resumido')}
          type="text"
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={5}
          {...register('bio')}
        />
        <Controller
          control={control}
          name="fiscal_code"
          render={({ field: { onChange, ref, value } }) => (
            <InputMask
              mask="999.999.999-99"
              type="text"
              onChange={e => {
                onChange(e);
              }}
              defaultValue={value}
            >
              <TextField
                label={t('CPF')}
                fullWidth
                variant="outlined"
                inputRef={ref}
              />
            </InputMask>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="fiscal_code"
          as={<ErrorMessageText />}
        />
        <div className="flex">
          <div className="w-10/12 pr-2">
            <TextField
              type="text"
              label={t('Registro Profissional')}
              variant="outlined"
              fullWidth
              {...register('doctor_profile.professional_code')}
            />
          </div>
          <div className="w-2/12">
            <Controller
              control={control}
              name="doctor_profile.professional_code_region"
              render={fields => (
                <TextField
                  select
                  label={t('Estado')}
                  variant="outlined"
                  fullWidth
                  inputRef={fields.field.ref}
                  {...fields.field}
                >
                  <MenuItem key={0} value={''}></MenuItem>
                  {Object.keys(stateLabels).map(option => (
                    <MenuItem key={option} value={option}>
                      {option.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="doctor_profile.professional_code_region"
              as={<ErrorMessageText />}
            />
          </div>
        </div>
        <ErrorMessage
          errors={errors}
          name="doctor_profile.professional_code"
          as={<ErrorMessageText />}
        />
        {/* <CRMUpload /> */}
        <Controller
          control={control}
          name="doctor_profile.office.country"
          render={fields => (
            <TextField
              select
              label={t('País')}
              variant="outlined"
              fullWidth
              inputRef={fields.field.ref}
              {...fields.field}
            >
              {availableCountries.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="doctor_profile.office.country"
          as={<ErrorMessageText />}
        />
        <TextField
          type="text"
          label={t('Estado')}
          variant="outlined"
          fullWidth
          {...register('doctor_profile.office.state')}
        />
        <ErrorMessage
          errors={errors}
          name="doctor_profile.office.state"
          as={<ErrorMessageText />}
        />
        <TextField
          type="text"
          label={t('Cidade')}
          variant="outlined"
          fullWidth
          {...register('doctor_profile.office.city')}
        />
        <ErrorMessage
          errors={errors}
          name="doctor_profile.office.city"
          as={<ErrorMessageText />}
        />
        <TextField
          type="text"
          label={t('Nome do Consultório')}
          variant="outlined"
          fullWidth
          {...register('doctor_profile.office.name')}
        />
        <ErrorMessage
          errors={errors}
          name="doctor_profile.office.name"
          as={<ErrorMessageText />}
        />
        <TextField
          type="text"
          label={t('Endereço')}
          variant="outlined"
          fullWidth
          {...register('doctor_profile.office.street_name')}
        />
        <TextField
          type="text"
          label={t('Complemento')}
          variant="outlined"
          fullWidth
          {...register('doctor_profile.office.complement')}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <label htmlFor="loading"> </label>
          <UpdateProfileButton bgColor="var(--caren-greenblue)" type="submit">
            {loading ? <LoadingComponent /> : t('Atualizar Perfil')}
          </UpdateProfileButton>
        </div>
      </FormContainer>
    </>
  );
}
