import { useRef } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import styled from 'styled-components';
import SendWhatsappDialog, {
  type SendWhatsappDialogHandles
} from '../../whatsapp/send';
import TextInput from '../../../components/inputs/input/text';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { useToast } from '../../../../contexts/ToastContext';

const Step = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .whatsapp {
    background-color: transparent;
    color: var(--caren-success);
    display: inline;
  }

  .field {
    width: 100%;
    display: flex;
    align-items: initial;
    justify-content: center;
    max-width: 450px;
  }

  > h3 {
    text-align: center;
  }

  .tip {
    text-align: center;

    > button {
      transition-property: color;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      &:hover {
        color: var(--caren-green);
      }
    }
  }

  > div {
    > p {
      margin: 10px 0;
      font-size: 14px;
      color: var(--caren-grey);
      text-align: center;
      max-width: 400px;
    }
  }

  > footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    @media (max-width: 636px) {
      width: 100%;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 20px;

      @media (max-width: 636px) {
        width: 100%;
        column-gap: 10px;
      }

      button {
        color: var(--caren-icongrey);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 250px;
        height: 40px;
        padding: 0 20px;
        border-radius: 5px;
        text-transform: uppercase;
        transition-property: background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

        @media (max-width: 636px) {
          min-width: unset;
          width: 100%;
        }

        &:disabled {
          filter: grayscale(1);
          cursor: not-allowed;
        }

        &.next {
          background-color: #fdbf71;
          &:hover {
            background-color: #f3ac4e;
          }
        }
      }
    }
  }
`;

type SuccessStepProps = {
  invitationLink: string;
  title?: string;
  subTitle?: string;
  enableWhatsapp?: boolean;
  onClose: () => void;
};

export default function SuccessStep({
  invitationLink,
  title = 'Tudo pronto! O convite para o agendamento foi enviado por e-mail.',
  subTitle = 'Você receberá uma notificação caso ele seja aceito.',
  enableWhatsapp = true,
  onClose
}: SuccessStepProps) {
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const sendWhatsappRef = useRef<SendWhatsappDialogHandles>(null);

  return (
    <Step>
      <CheckCircleIcon className="h-20 w-20 shrink-0 text-success" />

      <h3>{title}</h3>
      <h3>{subTitle}</h3>

      <br />

      <h3>Este é o link do convite:</h3>

      <div className="relative flex h-full w-full max-w-sm flex-grow flex-row items-start gap-x-2 pt-2">
        <TextInput ref={inputRef} value={invitationLink ?? ''} readOnly />
        <button
          className="grid h-10 w-10 shrink-0 place-items-center rounded-md border border-blue-650/50 bg-white text-blue-650"
          onClick={e => {
            navigator.clipboard.writeText(invitationLink);
            if (inputRef.current) {
              inputRef.current.select();
            }

            toast('Link copiado com sucesso!', { variant: 'success' });
          }}
        >
          <CopyIcon />
        </button>
      </div>

      {enableWhatsapp && (
        <h3 className="tip mb-2 mt-8">
          <strong>Atenção:</strong> caso queira enviar o link para o paciente,
          não esqueça de copiá-lo antes de concluir.{' '}
          <button
            className="whatsapp"
            type="button"
            onClick={() => sendWhatsappRef.current?.open()}
          >
            Você também pode enviar pelo seu WhatsApp.
          </button>
        </h3>
      )}

      <footer>
        <div>
          <button className="next" type="button" onClick={onClose}>
            <span>Concluir</span>
          </button>
        </div>
      </footer>

      <SendWhatsappDialog
        ref={sendWhatsappRef}
        invitationLink={invitationLink}
      />
    </Step>
  );
}
