import React from 'react';
import useCountdownClock from '../../../hooks/countdownGreenClock.hook';
import { DoctorGraph } from '../consult-wait-room.styles';

const CountdownClock = ({ consultation }) => {
  const countdownClock = useCountdownClock(consultation, false, '#F2E4C7');
  const options = {
    responsive: false,
    maintainAspectRatio: true,
    legend: {
      display: false,
      color: 'red',
    },
    tooltips: {
      enabled: false,
    },
    height: 100,
    cutoutPercentage: 70,
  };

  // NOT GOOD SOLUTION
  if (
    countdownClock &&
    countdownClock.datasets &&
    countdownClock.datasets[0] &&
    countdownClock.datasets[0].data[0] >= 100
  )
    return <canvas className="w-[300px] h-[200px]" />;

  return (
    <DoctorGraph
      height={200}
      zeroPadTime={0}
      data={countdownClock}
      options={options}
    />
  );
};

export default CountdownClock;
