import Participant from '../Participant';
import RoomControls from '../Controls';
import ClinicalEvaluationInfo from '../../Consultation/ClinicalEvaluationInfo';
import { type Session, type Token } from '../../../v2/entities/session';
import { ReactComponent as LogoBlack } from '../../../assets/images/logo-black.svg';
import React, { type ElementType, useEffect, useRef, useState } from 'react';
import { type TeleconsultationComplete } from '../../../v2/entities/consult';
import PersonImage from '../../../v2/components/data/personImage';
import { stringToCPF } from '../../../v2/utils/formatter';
import { getPatientBadges } from '../../../services/badges';
import { useToast } from '../../../contexts/ToastContext';
import CreateConsultDialog, {
  type CreateConsultDialogHandles
} from '../../../v2/dialogs/consult/create';
import { InsertInvitation, PictureInPictureAlt } from '@mui/icons-material';
import Counter from '../../../v2/components/data/counter';
import { XMarkIcon } from '@heroicons/react/24/solid';

type ParticipantRoomProps = {
  isDoctor?: boolean;
  session: Session;
  sessionToken: Token;
  consultation: TeleconsultationComplete;
  room: {
    localParticipant: {
      state: unknown;
    };
  };
  handleDisconnectCall: () => void;
  remoteParticipantCam: ElementType;
  remoteIsConnected: boolean;
  roomRunningDuration: null | number;
};

export default function ParticipantRoom({
  isDoctor = false,
  room,
  session,
  sessionToken,
  handleDisconnectCall,
  consultation,
  remoteParticipantCam: RemoteParticipantCam,
  remoteIsConnected,
  roomRunningDuration
}: ParticipantRoomProps) {
  const toast = useToast();
  const createConsultDialogRef = useRef<CreateConsultDialogHandles>(null);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [badges, setBadges] = useState<{
    Comorbidades?: Array<{ name: string; detail: string | null }>;
    Alergia?: Array<{ name: string; detail: string | null }>;
  } | null>(null);

  const tooglePictureInPicture = () => {
    const video = document.querySelector(
      '#remote-video-cam'
    ) as HTMLVideoElement;

    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else if (document.pictureInPictureEnabled && video) {
      video.requestPictureInPicture();
    }
  };

  const isPictureInPictureEnabled = document.pictureInPictureEnabled;

  useEffect(() => {
    if (!consultation || !isDoctor) return;

    const getBadges = async () => {
      try {
        const { allergy, comorbidities } = await getPatientBadges(
          consultation.key
        );

        setBadges({ Alergia: allergy, Comorbidades: comorbidities });
      } catch (error) {
        toast(
          'Não foi possível carregar as alergias e comorbidades do paciente.',
          {
            variant: 'error'
          }
        );
      }
    };

    getBadges();
  }, [isDoctor]);

  return (
    <div className="relative flex h-screen w-full items-stretch justify-between">
      <div
        data-sidebar={openSidebar}
        className="flex h-full w-full flex-grow flex-col items-start px-5 pt-5 data-[sidebar=true]:h-[62vh] sm:px-10 sm:pt-9 md:px-20 md:data-[sidebar=true]:h-full lg:px-24"
      >
        <LogoBlack className="h-auto w-24 shrink-0" />
        <div className="relative mt-10 flex h-full w-full flex-grow flex-col items-start justify-end overflow-hidden rounded-2xl bg-black ">
          <div className="h-full w-full [&_video]:h-full [&_video]:w-full [&_video]:object-contain">
            <RemoteParticipantCam />
          </div>
          <div className="absolute bottom-4 right-4 z-10 h-24 w-44 overflow-hidden rounded-2xl md:bottom-auto md:top-4 md:h-40 md:w-60 [&_video]:h-full [&_video]:w-full [&_video]:object-contain">
            <Participant
              participant={room.localParticipant}
              name={session.name}
              avatarUrl={session.avatar_url}
              isLocalParticipant
            />
          </div>
          {isDoctor && roomRunningDuration !== null && (
            <div className="absolute bottom-4 left-4 z-10">
              <Counter
                paused={!remoteIsConnected}
                initialTime={roomRunningDuration}
              />
            </div>
          )}

          {isPictureInPictureEnabled && (
            <div className="absolute right-4 top-4 z-10 md:bottom-4 md:top-auto">
              <button
                type="button"
                className="text-white"
                onClick={() => {
                  tooglePictureInPicture();
                }}
              >
                <PictureInPictureAlt />
              </button>
            </div>
          )}
        </div>
        <RoomControls
          disabled={room?.localParticipant?.state === 'reconnecting'}
          handleDisconnectCall={handleDisconnectCall}
          sessionToken={sessionToken}
          onClickMoreButton={() => {
            setOpenSidebar(true);
          }}
        />
      </div>
      {isDoctor && (
        <>
          {openSidebar && (
            <div
              role="presentation"
              className="fixed inset-0 z-20 bg-black/20 lg:hidden"
              onClick={() => {
                setOpenSidebar(false);
              }}
            />
          )}
          <div
            data-open={openSidebar}
            className="absolute bottom-0 right-0 z-30 hidden h-[50vh] w-full shrink-0 flex-col items-stretch justify-start gap-y-5 overflow-y-auto bg-white px-5 pt-9 data-[open=true]:flex md:h-full md:w-[24rem] md:px-8 lg:relative lg:flex xl:w-[26rem] xl:px-9"
          >
            <div className="flex w-full items-center gap-x-4">
              <div className="h-12 w-12 shrink-0">
                <PersonImage
                  name={
                    isDoctor
                      ? consultation.patient.name
                      : consultation.doctor.name
                  }
                  image={
                    isDoctor
                      ? consultation.patient.avatar_url
                      : consultation.doctor.avatar_url
                  }
                />
              </div>
              <div className="flex w-full flex-grow flex-col">
                <p className="font-nunito text-3xl">
                  {isDoctor
                    ? consultation.patient.name
                    : consultation.doctor.name}
                </p>
                <p className="text-base text-gray-350">
                  {isDoctor
                    ? `CPF: ${stringToCPF(consultation.patient.fiscal_code)}`
                    : consultation.doctor.specialties
                        ?.map(speciality => speciality.name)
                        .join(', ') ?? ''}
                </p>
              </div>
              <button
                type="button"
                onClick={() => createConsultDialogRef.current?.open()}
                className="mr-5 flex items-center justify-center text-gray-450 md:mr-0 lg:hidden"
              >
                <InsertInvitation sx={{ fontSize: '25px' }} />
              </button>
              <button
                type="button"
                className="absolute right-2 top-2 flex items-center justify-center lg:hidden"
                onClick={() => {
                  setOpenSidebar(false);
                }}
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            {badges && (
              <div className="flex h-fit max-h-40 w-full shrink-0 flex-wrap gap-2 overflow-y-auto">
                {React.Children.toArray(
                  badges.Alergia?.map(item => (
                    <p className="rounded-2xl bg-warning px-2 py-1 text-sm">
                      {item.name} {item.detail && `(${item.detail})`}
                    </p>
                  ))
                )}
                {React.Children.toArray(
                  badges.Comorbidades?.map(item => (
                    <p className="overflow-clip break-words rounded-2xl bg-error px-2 py-1 text-sm font-semibold text-white">
                      {item.name} {item.detail && `(${item.detail})`}
                    </p>
                  ))
                )}
              </div>
            )}
            <ClinicalEvaluationInfo consultation={consultation} isWidget />
            <CreateConsultDialog ref={createConsultDialogRef} />
          </div>
        </>
      )}
    </div>
  );
}
