import React, { useEffect, useRef, useCallback } from 'react';
import { useSocket } from '../../../hooks/SocketContext';
import { ROLE_PATIENT, ROLE_PROFESSIONAL } from '../../../routes/constants';

const LiveWaitRoomStatus = (props) => {
  const {
    consultation,
    userType,
    callPatient,
    isReady,
    onOpenRoom,
    onCloseRoom,
    setOnline,
    setConnected,
    setPatientIsReady,
  } = props;

  const opened = useRef(false);

  const CHANNEL_TOPIC = `teleconsultation_waiting_room:${consultation?.key}`;
  const CHANNEL_PAYLOAD = {};

  const otherType =
    userType === ROLE_PATIENT ? ROLE_PROFESSIONAL : ROLE_PATIENT;

  const hasOtherParty = (payload) =>
    (payload?.[otherType]?.metas || []).length > 0;

  const patientIsReady = (payload) =>
    payload?.[ROLE_PATIENT]?.metas[0]?.is_ready;

  const handlePresenceState = useCallback(
    (channel, payload) => {
      if (channel == null) return;

      setOnline(hasOtherParty(payload));
      setPatientIsReady(patientIsReady(payload));
    },
    [setOnline]
  );

  const handlePresenceDiff = useCallback(
    (channel, payload) => {
      if (channel == null) return;

      if (hasOtherParty(payload?.joins)) {
        setOnline(true);

        setPatientIsReady(patientIsReady(payload?.joins));
      }

      if (hasOtherParty(payload?.leaves) && !hasOtherParty(payload?.joins)) {
        setOnline(false);

        if (opened.current) {
          onCloseRoom(payload);
        }
      }
    },
    [opened, setOnline, onCloseRoom]
  );

  const handleOpenRoom = useCallback(
    (channel, payload) => {
      if (channel == null) return null;

      opened.current = true;

      return onOpenRoom(payload);
    },
    [onOpenRoom, opened]
  );

  const listenToMessages = useCallback(
    (joined, _payload) => {
      joined.on('presence_state', (e) => handlePresenceState(joined, e));
      joined.on('presence_diff', (e) => handlePresenceDiff(joined, e));
      joined.on('open_room', (e) => handleOpenRoom(joined, e));

      setConnected(true);
    },
    [setConnected, handlePresenceState, handlePresenceDiff, handleOpenRoom]
  );

  const notifyClose = useCallback(
    (_socket, _payload) => {
      setConnected(false);
      setOnline(false);
    },
    [setConnected, setOnline]
  );

  const { channel: connectedChannel } = useSocket(
    CHANNEL_TOPIC,
    CHANNEL_PAYLOAD,
    {
      enableJoin: !!consultation?.key && consultation?.room_opened_at == null,
      afterJoin: listenToMessages,
      afterClose: notifyClose,
    }
  );

  useEffect(() => {
    if (connectedChannel != null && callPatient) {
      connectedChannel.push('call_patient', {});
    }
  }, [connectedChannel, callPatient]);

  useEffect(() => {
    if (connectedChannel != null && isReady !== null) {
      connectedChannel.push('set_ready', { value: isReady });
    }
  }, [connectedChannel, isReady]);

  return <></>;
};

export default LiveWaitRoomStatus;
