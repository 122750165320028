import { type ButtonHTMLAttributes, type ElementType } from 'react';
import { twMerge } from 'tailwind-merge';

const BUTTON_STYLES = {
  yellow: {
    icon: 'text-gray-950',
    text: 'text-gray-950',
    background: 'bg-yellow-650 hover:bg-yellow-750',
    disabled: 'disabled:grayscale'
  },
  neutral: {
    icon: 'text-warning group-hover:text-gray-950',
    text: 'text-blue-650 hover:text-gray-950',
    background: 'bg-white hover:bg-yellow-650',
    disabled: 'disabled:opacity-30'
  },
  success: {
    icon: 'text-white',
    text: 'text-white',
    background: 'bg-success hover:bg-success-dark',
    disabled: 'disabled:grayscale'
  }
};

type ButtonProps = {
  label: string
  icon?: ElementType
  colors?: 'yellow' | 'neutral' | 'success'
  size?: 'small' | 'large' | 'medium'
} & ButtonHTMLAttributes<HTMLButtonElement>

export default function Button({
  label,
  icon: Icon,
  colors = 'neutral',
  size = 'small',
  ...rest
}: ButtonProps) {
  return (
    <button
      className={twMerge(
        'px-2.5 py-3 flex justify-center items-center gap-x-2 group transition-all duration-300 rounded-md disabled:cursor-not-allowed  font-nunito',
        BUTTON_STYLES[colors].background,
        BUTTON_STYLES[colors].text,
        BUTTON_STYLES[colors].disabled,
        size === 'small'
          ? 'w-full sm:w-fit h-10 text-sm font-semibold'
          : size === 'large'
          ? 'w-full h-14 text-base font-bold'
          : 'w-full h-12 text-base font-semibold'
      )}
      {...rest}
    >
      {Icon && (
        <Icon
          className={twMerge(
            'transition-all duration-300 inline w-6 h-6 shrink-0',
            BUTTON_STYLES[colors].icon
          )}
        />
      )}
      {label}
    </button>
  );
}
