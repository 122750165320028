import { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import BaseDialog from '../base';
import { useHistory } from 'react-router-dom';
import { CreditCardIcon } from '@heroicons/react/24/solid';

const Container = styled.div`
  width: 100%;
  height: 100%;

  > .header {
    color: var(--caren-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: semibold;
    text-align: center;
    row-gap: 10px;
  }

  > .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    p {
      color: var(--caren-disabled-text);
      font-size: 12px;
    }

    button {
      padding: 10px 30px;
      background-color: var(--caren-bluebtn);
      border-radius: 6px;
      color: #262c2e;
      font-weight: bold;
      transition-property: background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      :hover {
        background-color: var(--caren-greenblue);
      }
    }

    .plans-button {
      padding: 10px 30px;
      background-color: #ffc476;
      border-radius: 6px;
      color: #262c2e;
      font-weight: bold;
      transition-property: background-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;

      :hover {
        background-color: #f3ac4e;
        color: #262c2e;
      }
    }
  }

  > p {
    color: var(--caren-orange);
    font-size: 14px;
    text-align: center;
  }
`;

type ExceededAppointmentsDialogHandles = {
  open: () => void;
};

type ExceededAppointmentDialogProps = {
  onClose?: () => void;
  disableBackdropClick?: boolean;
};

const ExceededAppointmentsDialog = forwardRef<
  ExceededAppointmentsDialogHandles,
  ExceededAppointmentDialogProps
>(({ onClose, disableBackdropClick = false }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    }
  }));

  return (
    <BaseDialog
      title="Limite de agendamentos atingido"
      isOpen={isOpen}
      onClose={() => {
        if (onClose) {
          onClose();
        }
        setIsOpen(false);
      }}
      disableBackdropClick={disableBackdropClick}
    >
      <Container>
        <div className="header">
          <CreditCardIcon className="h-16 w-16 shrink-0" />
          <p className="mb-4 w-2/3 text-lg">
            Você atingiu o limite máximo de agendamentos de consultas no plano
            free.
          </p>
          <p className="w-2/3 text-lg">
            Antes de prosseguir, é necessário adquirir um de nossos planos.
          </p>
        </div>
        <p>Clique no botão abaixo para conhecer o melhor plano para você.</p>
        <div className="footer">
          <button
            type="button"
            className="plans-button"
            onClick={() => {
              setIsOpen(false);
              localStorage.setItem('doctor-profile-tab', 'plan_details');
              history.push('/perfil', { view: 'plans' });
            }}
          >
            Escolha seu plano
          </button>
        </div>
      </Container>
    </BaseDialog>
  );
});

ExceededAppointmentsDialog.displayName = 'ExceededAppointmentsDialog';
export type { ExceededAppointmentsDialogHandles };
export default ExceededAppointmentsDialog;
