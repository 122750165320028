import React, { useState, useEffect } from 'react';
import Modal from '../index';

import {
  ModalWrapper,
  WrapperLoading,
  ConsultationContainer,
  PatientInfoContainer
} from './styles';

import { anamnesisDetailService } from '../../../services/anamnesis-detail.service';
import { useToast } from '../../../contexts/ToastContext';

import PatientInfo from '../../Consultation/PatientInfo';
import Anamnesis from '../../Twilio/Room/types/Professional/Tabs/Anamnesis';

export default function AnamneseModal({
  openAnamneseModal,
  setOpenAnamneseModal,
  consultation
}) {
  const toast = useToast();

  const [anamnesisDetail, setAnamnesisDetail] = useState(null);

  function handleToggleModal() {
    setOpenAnamneseModal(!openAnamneseModal);
  }

  useEffect(async () => {
    if (!consultation) return;

    const key =
      consultation?.anamnesis_key ||
      consultation?.teleconsultation?.anamnesis_key;

    if (key) {
      try {
        const { data } = await anamnesisDetailService(key, {
          with_history: false
        });

        setAnamnesisDetail(data);
      } catch (error) {
        toast('Ops, não foi possível carregar o pré-atendimento...', {
          variant: 'error'
        });
      }
    }
  }, [consultation]);

  return (
    <Modal
      handleOpenModal={handleToggleModal}
      openModal={openAnamneseModal}
      modalTitle="Anamnese da teleconsulta"
      modalTitleColor="black"
      modalWidth="960px"
    >
      <ModalWrapper>
        {consultation && anamnesisDetail ? (
          <>
            <PatientInfoContainer>
              <PatientInfo
                patient={consultation.patient}
                teleconsultationKey={consultation.teleconsultation.key}
                scheduledDatetime={
                  consultation.teleconsultation.scheduled_datetime
                }
              />
            </PatientInfoContainer>

            <ConsultationContainer>
              <Anamnesis
                detail={anamnesisDetail}
                showClinicalEvaluation={false}
              />
            </ConsultationContainer>
          </>
        ) : (
          <WrapperLoading>
            <span />
            <span />
          </WrapperLoading>
        )}
      </ModalWrapper>
    </Modal>
  );
}
