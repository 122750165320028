import styled from 'styled-components/macro';

export const ChangePasswordButton = styled.button`
  border-radius: 5px;
  height: 35px;
  margin: 10px 0;
  width: 150px;
  font-size: 16px;
  margin-right: auto;
  color: var(--caren-icongrey);
  background-color: var(--caren-green);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PasswordHints = styled.div`
  display: block;
  padding: 0 0 10px;

  > div {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    svg.invalid path {
      fill: #dc3545;
    }

    svg.valid path {
      fill: #28a745;
    }

    svg {
      display: block;
      position: relative;
      top: -1px;
      margin-right: 5px;
    }

    span {
      display: block;
    }
  }
`;

export const ErrorMessageText = styled.p`
  color: red;
  font-size: 0.8em;
`;
